import React from 'react';
import {
    useProductAttributes,
    useRoGridTestProductProvider,
    useRoNationalEvaluation,
    useRoTestDataProductProvider
} from '../../../state';
import {GridTest} from "./testTypes/GridTest";
import {TrueOrFalse} from "./testTypes/TrueOrFalse";
import {AnimatedLoginPrompt} from "./assets/AnimatedLoginPrompt";
import {SubscriptionAccessDenied} from "./assets/SubscriptionAccessDenied";
import {DescriptionSk} from "../Skeleton/DescriptionSk";
import {NationalEvaluation} from "./testTypes/NationalEvaluation";

export const TestComponents = () => {
    const {testData, loading, isInTestCategory} = useProductAttributes();
    const {roTrueFalseTestData} = useRoTestDataProductProvider();
    const {gridTestData} = useRoGridTestProductProvider();
    const {nationalEvaluationData} = useRoNationalEvaluation();

    if (loading) {
        return <DescriptionSk/>;
    }

    if (!isInTestCategory) {
        return null;
    }

    const renderTestComponent = (status: string, Component: React.ComponentType) => {
        switch (status) {
            case 'userNotAuthenticated':
                return <AnimatedLoginPrompt/>;
            case 'noSubscriptionAccess':
                return <SubscriptionAccessDenied/>;
            case 'success':
                return <Component/>;
            default:
                return null;
        }
    };

    return (
        <>
            {roTrueFalseTestData && renderTestComponent(roTrueFalseTestData.status, TrueOrFalse)}
            {gridTestData && renderTestComponent(gridTestData.status, GridTest)}
            {nationalEvaluationData && renderTestComponent(nationalEvaluationData.status, NationalEvaluation)}
        </>
    );
}
