import React from 'react';
import {AlertCircle, ArrowRight} from 'lucide-react';

export const SubscriptionAccessDenied = () => {
    const subscriptionUrl = `${window.BASE_URL}subscriptii.html`;

    return (
        <div className="w-full flex flex-col items-center justify-center p-4 md:p-8">
            <div className="bg-card-bg shadow-round-shadow flex flex-col items-center justify-center p-8 w-full md:w-1/2 rounded-xl">
                <div className="flex items-center justify-center mb-6">
                    <AlertCircle className="w-12 h-12 text-yellow-500 animate-bounce" />
                </div>

                <h2 className="text-4xl font-bold font-font-p text-gray-800 mb-4">
                    🎯 Acest conținut necesită un abonament specific
                </h2>

                <p className="text-2xl text-center font-font-s text-gray-700 mb-6">
                    Pentru a accesa acest test, ai nevoie de abonamentul potrivit! ✨
                </p>

                <div className="flex flex-col items-center">
                    <p className="text-2xl font-semibold text-center font-font-s text-text-color mb-6">
                        Alege abonamentul pentru clasa ta și: <br/>
                        🎥 Accesează toate testele din programa ta <br/>
                        📚 Învață în ritmul tău <br/>
                        🌟 Ai acces la toate rezultatele testelor tale si alte beneficii
                    </p>

                    <a href={subscriptionUrl} className="no-underline hover:no-underline">
                        <button className="text-2xl font-font-p bg-olive-color hover:bg-olive-color text-white font-bold font-font-p py-3 px-6
                            flex items-center gap-2 hover:text-white transition duration-300 ease-in-out
                            transform hover:scale-105 rounded-full">
                            Vezi abonamentele disponibile
                            <ArrowRight className="w-6 h-6 font-bold" />
                        </button>
                    </a>
                </div>
            </div>
        </div>
    );
};
