import React, { useCallback } from 'react';
import { QuillEditor } from "./QuillEditor";

export const GridSection = ({subject, part, updateGridText, updateGridSubject}) => {
    const handleAnswerChange = useCallback((answerId: number) => {
        updateGridSubject(part, subject.id, 'correctAnswer', answerId);
    }, [part, subject.id, updateGridSubject]);

    const handleInputChange = useCallback((key: string, value: string) => {
        updateGridSubject(part, subject.id, key, value);
    }, [part, subject.id, updateGridSubject]);

    return (
        <div className="bg-white shadow-sm mb-4 rounded-lg">
            <div className="flex flex-col gap-4">
                <QuillEditor
                    value={subject.questionText}
                    onEditorChange={(content) => updateGridText(part, subject.id, content)}
                    placeholder="Introduceți textul subiectului..."
                    className="bg-white h-48 mb-20"
                />
                <div className="space-y-4">
                    {[
                        {key: 'answer1', id: 1, value: subject.answer1},
                        {key: 'answer2', id: 2, value: subject.answer2},
                        {key: 'answer3', id: 3, value: subject.answer3},
                        {key: 'answer4', id: 4, value: subject.answer4},
                    ].map(ans => (
                        <label key={ans.id} className="flex items-center gap-4 w-full">
                            <input
                                type="radio"
                                name={`answer-${part}-${subject.id}`}
                                checked={subject.correctAnswer === ans.id}
                                onChange={() => handleAnswerChange(ans.id)}
                                className="w-4 h-4"
                            />
                            <input
                                type="text"
                                value={ans.value || ''}
                                onChange={(e) => handleInputChange(ans.key, e.target.value)}
                                placeholder={`Răspuns ${ans.id}`}
                                className="flex-1 border p-2 rounded"
                            />
                        </label>
                    ))}
                </div>
            </div>
        </div>
    );
};
