import React, {useState} from 'react';
import {useAdminTestData} from '../../../state';
import {TestViewModal} from "./assets/TestViewModal";
import {Eye} from "lucide-react";

export const TestDataTable = () => {
    const {
        paginatedData,
        loading,
        error,
        currentPage,
        totalPages,
        handleUpdateTestData,
        handlePageChange,
        handleDateFilterChange,
        dateFrom,
        dateTo
    } = useAdminTestData();

    const [editingRow, setEditingRow] = useState<number | null>(null);
    const [editValue, setEditValue] = useState<string | null>(null);
    const [viewingTest, setViewingTest] = useState(null);

    const [dateFromInput, setDateFromInput] = useState(dateFrom || '');
    const [dateToInput, setDateToInput] = useState(dateTo || '');

    const handleFilterApply = () => {
        handleDateFilterChange(dateFromInput || null, dateToInput || null);
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    const handleEdit = (rowData) => {
        setEditingRow(rowData.entity_id);

        // Extrage valoarea corectă pentru editare în funcție de tipul testului
        if (rowData.test_type === 'nationalEvaluation') {
            try {
                const parsedScore = JSON.parse(rowData.test_score);
                if (parsedScore.test_score && parsedScore.test_score.total) {
                    setEditValue(parsedScore.test_score.total.score.toString());
                } else {
                    setEditValue('0');
                }
            } catch {
                setEditValue('0');
            }
        } else {
            try {
                const parsedScore = JSON.parse(rowData.test_score);
                setEditValue(parsedScore.test_score.total_points.toString());
            } catch {
                setEditValue('0');
            }
        }
    };

    const handleSave = async (rowData) => {
        try {
            // Pentru evaluare națională, avem format diferit
            if (rowData.test_type === 'nationalEvaluation') {
                const parsedScore = JSON.parse(rowData.test_score);

                // Actualizăm scorul total păstrând restul structurii intactă
                parsedScore.test_score.total.score = parseFloat(editValue);

                // Log payload pentru debugging
                console.log("Payload trimis (evaluare națională):", {
                    entity_id: rowData.entity_id,
                    test_score: JSON.stringify(parsedScore),
                });

                await handleUpdateTestData({
                    entity_id: rowData.entity_id,
                    test_score: JSON.stringify(parsedScore),
                });
            } else {
                // Pentru alte tipuri de teste, folosim formatul standard
                const parsedScore = rowData.test_score ? JSON.parse(rowData.test_score) : { test_score: { total_points: 0, max_possible_points: 0 } };

                // Actualizăm doar `total_points`
                const updatedScore = {
                    ...parsedScore,
                    test_score: {
                        ...parsedScore.test_score,
                        total_points: parseInt(editValue, 10),
                    },
                };

                // Log payload pentru debugging
                console.log("Payload trimis:", {
                    entity_id: rowData.entity_id,
                    test_score: JSON.stringify(updatedScore),
                });

                await handleUpdateTestData({
                    entity_id: rowData.entity_id,
                    test_score: JSON.stringify(updatedScore),
                });
            }

            setEditingRow(null);
            setEditValue(null);
        } catch (error) {
            console.error('Failed to update score:', error);
        }
    };

    const handleCancel = () => {
        setEditingRow(null);
        setEditValue(null);
    };

    // Funcție pentru afișarea corectă a scorului în funcție de tipul testului
    const formatTestScore = (row) => {
        try {
            const parsedScore = JSON.parse(row.test_score);

            // Dacă e evaluare națională, folosim formatul specific
            if (row.test_type === 'nationalEvaluation' && parsedScore.test_score && parsedScore.test_score.total) {
                return `${parsedScore.test_score.total.score} / ${parsedScore.test_score.total.max_score}`;
            }

            // Pentru celelalte teste, folosim formatul standard
            return `${parsedScore.test_score.total_points} / ${parsedScore.test_score.max_possible_points}`;
        } catch {
            return row.test_score || "No score"; // Dacă nu poate fi parsată sau e null
        }
    };

    return (
        <div className="admin__data-grid-wrap">
            <div className="admin__data-grid-header-row py-5">
                <div className="data-grid-filters">
                    <div className="data-grid-filters-row">
                        <div className="data-grid-filters-action">
                            <input
                                type="date"
                                value={dateFromInput}
                                onChange={(e) => setDateFromInput(e.target.value)}
                                className="admin__control-text"
                            />
                            <input
                                type="date"
                                value={dateToInput}
                                onChange={(e) => setDateToInput(e.target.value)}
                                className="admin__control-text"
                                style={{marginLeft: '10px'}}
                            />
                            <button
                                onClick={handleFilterApply}
                                className="action-secondary"
                                style={{marginLeft: '10px'}}
                            >
                                Apply Filters
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <table className="data-grid">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Customer Email</th>
                    <th>Product SKU</th>
                    <th>Test Type</th>
                    <th>Level</th>
                    <th>Score</th>
                    <th>Created At</th>
                    <th>For School</th>
                    <th>Corectare</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {paginatedData.map(row => (
                    <tr key={row.entity_id}>
                        <td>{row.entity_id}</td>
                        <td>{row.customer_email}</td>
                        <td>{row.product_sku}</td>
                        <td>{row.test_type}</td>
                        <td>
                            {row.class_lvl_code} | {row.module_lvl_code} | {row.difficulty_lvl_code}
                        </td>
                        <td>
                            {editingRow === row.entity_id ? (
                                <input
                                    type="number"
                                    value={editValue ?? ''}
                                    onChange={(e) => setEditValue(e.target.value)}
                                    className="admin__control-text"
                                />
                            ) : (
                                formatTestScore(row)
                            )}
                        </td>
                        <td>{row.created_at}</td>
                        <td
                            className={row.test_for_school === 1 ? '!bg-green-200 !text-green-800' : '!bg-red-200 !text-red-800'}
                        >
                            {row.test_for_school === 1 ? 'Da' : 'Nu'}
                        </td>
                        <td>{row.correction_type}</td>
                        <td>
                            <div className="flex space-x-2">
                                {editingRow === row.entity_id ? (
                                    <>
                                        <button onClick={() => handleSave(row)}>
                                            Save
                                        </button>
                                        <button onClick={handleCancel}>
                                            Cancel
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <button onClick={() => handleEdit(row)}>
                                            Edit
                                        </button>
                                        <button
                                            onClick={() => setViewingTest(row)}
                                            className="flex items-center"
                                        >
                                            <Eye className="h-4 w-4 mr-1"/>
                                            View
                                        </button>
                                    </>
                                )}
                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

            <div className="admin__data-grid-pager py-5">
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="action-previous w-auto p-2"
                >
                    Previous
                </button>

                <span className="admin__control-support-text">
                    Page {currentPage} of {totalPages}
                </span>

                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="action-next w-auto p-2"
                >
                    Next
                </button>
            </div>

            {viewingTest && (
                <TestViewModal
                    isOpen={!!viewingTest}
                    onClose={() => setViewingTest(null)}
                    testData={viewingTest}
                />
            )}
        </div>
    );
};
