import React from 'react';
import { QuillEditor } from "./QuillEditor";

export const RequirementsSection = ({requirements, updateRequirement, startIndex = 0}) => {
    return (
        <div className="bg-white shadow-sm mb-8 rounded">
            <div className="space-y-8">
                {requirements.map((requirement, index) => (
                    <div key={requirement.id} className="space-y-4">
                        <div className="flex flex-col gap-4">
                            <h3 className="text-2xl font-semibold mb-4 text-wood-color">{startIndex + index + 1}</h3>
                            <QuillEditor
                                value={requirement.text}
                                onEditorChange={(content) => updateRequirement(requirement.id, content)}
                                placeholder={`Introduceți cerința ${requirement.id}...`}
                                className="bg-white h-48 mb-20"
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
