import React from 'react';

export const VideoLinks = () => {

    return (
        <div className="w-full grid grid-cols-1 md:grid-cols-2">
            <div className="bg-olive-color/80 text-white p-10 flex flex-col justify-center items-center p-10">
                <a href="/lectii/gramatica.html"
                   className="max-w-[720px] text-white p-10 flex flex-col justify-center items-center transition-transform transform
                            hover:scale-105 hover:text-white visited:text-white hover:no-underline">
                    <h2 className="text-6xl font-font-p font-bold text-center">Lecții Video Gramatică</h2>
                    <p className="text-3xl font-font-s mt-2 text-center">Lecții interactive de gramatică pentru clasele 5-8</p>
                </a>
            </div>
            <div className="bg-peru-color/80 text-white p-10 flex flex-col justify-center items-center p-10">
                <a href="/lectii/literatura.html"
                   className="max-w-[720px] text-text-color p-10 flex flex-col justify-center items-center transition-transform transform
                            hover:scale-105 hover:text-white visited:text-white hover:no-underline">
                    <h2 className="text-6xl font-font-p font-bold text-center">Lecții Video Literatură</h2>
                    <p className="text-3xl font-font-s mt-2 text-center">Lecții captivante de literatură pentru clasele 5-8</p>
                </a>
            </div>
        </div>
    );
};

