import React, { useEffect, useRef } from 'react';
import Player from '@vimeo/player';

interface VimeoVideoPreviewProps {
    vimeoId: string;
}

export const VimeoVideoPreview = ({ vimeoId }: VimeoVideoPreviewProps) => {
    const iframeRef = useRef<HTMLIFrameElement>(null);

    useEffect(() => {
        if (iframeRef.current) {
            const player = new Player(iframeRef.current);

            // Set video to start from the beginning, play and mute
            player.setCurrentTime(0);
            player.setVolume(1);
            player.play();

            // Stop video after 20 seconds
            const stopAfterSeconds = () => {
                player.getCurrentTime().then((seconds) => {
                    if (seconds >= 20) {
                        player.pause();
                    }
                });
            };

            const interval = setInterval(stopAfterSeconds, 1000);

            return () => {
                clearInterval(interval);
                player.destroy();
            };
        }
    }, []);

    return (
        <div className="w-full p-2 md:p-8">
            <iframe
                ref={iframeRef}
                src={`https://player.vimeo.com/video/${vimeoId}?autoplay=1&loop=1&muted=0&controls=0`}
                className="w-full relative top-0 left-0 h-[200px]"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
            />
        </div>
    );
};
