import React, {useState} from 'react';
import {useProductAttributes, useRoNationalEvaluation} from '../../../../state';
import {SaveTestBtn, ErrorMessage} from '../../../../../../general';
import {DescriptionSk} from '../../Skeleton/DescriptionSk';
import {Timer} from '../assets/Timer';
import {EvNatTestResult} from "../assets/EvNatTestResult";

export const NationalEvaluation = () => {
    const {productSku} = useProductAttributes() || {};
    const {
        nationalEvaluationData,
        saveTestData,
        setTestCompleted,
        isTestCompleted,
        testScore,
        mutationError,
        clearMutationError,
        loading,
    } = useRoNationalEvaluation();

    // User answers state
    const [userAnswers, setUserAnswers] = useState({
        grid: {},
        trueFalse: {},
        textAnswers: {},
        multiAnswers: {}
    });

    const [saveMessage, setSaveMessage] = useState<string | null>(null);
    const [isSaving, setIsSaving] = useState(false);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [startTime, setStartTime] = useState<string | null>(null);
    const [hasStarted, setHasStarted] = useState(false);

    // Extragem datele din structura primită în răspunsul GraphQL
    const testData = nationalEvaluationData?.nationalEvaluation || null;

    // Loading state
    if (loading) {
        return <DescriptionSk/>;
    }

    // Error state
    if (!nationalEvaluationData || nationalEvaluationData.status === 'error' || nationalEvaluationData.status === 'noTestFound') {
        return <p className="text-red-500 p-4">
            {'Eroare la încărcarea testului.'}
        </p>;
    }

    // Test completion state
    if (isTestCompleted && testScore?.test_score) {
        return <EvNatTestResult score={testScore} saveMessage={saveMessage}/>;
    }

    // Handler for requirement text answers
    const handleRequirementChange = (requirementId: number, value: string, part: string) => {
        if (!hasStarted) {
            setHasStarted(true);
            setStartTime(new Date().toISOString());
        }

        const key = `${part}-requirement-${requirementId}`;
        setUserAnswers(prev => ({
            ...prev,
            textAnswers: {
                ...prev.textAnswers,
                [key]: value
            }
        }));

        // Clear error if exists
        if (errors[key]) {
            setErrors(prev => {
                const newErrors = {...prev};
                delete newErrors[key];
                return newErrors;
            });
        }
    };

    // Handler for grid questions
    const handleGridChange = (questionId: number, answerId: number, part: string) => {
        if (!hasStarted) {
            setHasStarted(true);
            setStartTime(new Date().toISOString());
        }

        const key = `${part}-${questionId}`;
        setUserAnswers(prev => ({
            ...prev,
            grid: {
                ...prev.grid,
                [key]: answerId
            }
        }));

        // Clear error if exists
        const errorKey = `grid-${key}`;
        if (errors[errorKey]) {
            setErrors(prev => {
                const newErrors = {...prev};
                delete newErrors[errorKey];
                return newErrors;
            });
        }
    };

    // Handler for true/false questions
    const handleTrueFalseChange = (questionId: number, value: boolean, part: string) => {
        if (!hasStarted) {
            setHasStarted(true);
            setStartTime(new Date().toISOString());
        }

        const key = `${part}-${questionId}`;
        setUserAnswers(prev => ({
            ...prev,
            trueFalse: {
                ...prev.trueFalse,
                [key]: value
            }
        }));

        // Clear error if exists
        const errorKey = `tf-${key}`;
        if (errors[errorKey]) {
            setErrors(prev => {
                const newErrors = {...prev};
                delete newErrors[errorKey];
                return newErrors;
            });
        }
    };

    // Handler pentru întrebări cu răspunsuri multiple
    const handleMultiAnswerChange = (questionId: number, answerId: number, value: string, part: string) => {
        if (!hasStarted) {
            setHasStarted(true);
            setStartTime(new Date().toISOString());
        }

        const key = `${part}-${questionId}-${answerId}`;
        setUserAnswers(prev => ({
            ...prev,
            multiAnswers: {
                ...prev.multiAnswers,
                [key]: value
            }
        }));

        // Clear error if exists
        const errorKey = `multi-answer-${part}-${questionId}-${answerId}`;
        if (errors[errorKey]) {
            setErrors(prev => {
                const newErrors = {...prev};
                delete newErrors[errorKey];
                return newErrors;
            });
        }
    };

    // Handler for form submission
    const handleSubmit = async () => {
        // Validation
        const newErrors: Record<string, string> = {};
        let hasErrors = false;

        if (testData?.subiectI?.partA?.multipleAnswers) {
            testData.subiectI.partA.multipleAnswers.forEach(question => {
                // Verificăm dacă există cel puțin un răspuns pentru această întrebare
                const hasAnyAnswer = question.answers.some(answer => {
                    const key = `partA-${question.id}-${answer.id}`;
                    return userAnswers.multiAnswers[key]?.trim();
                });

                if (!hasAnyAnswer) {
                    // Setăm o eroare pentru întreaga întrebare, nu pentru fiecare câmp de răspuns
                    newErrors[`multi-question-partA-${question.id}`] = 'Completează cel puțin un răspuns';
                    hasErrors = true;
                }
            });
        }

        // Validate Part A grid questions
        if (testData?.subiectI?.partA?.gridQuestions) {
            testData.subiectI.partA.gridQuestions.forEach(question => {
                const key = `partA-${question.id}`;
                if (userAnswers.grid[key] === undefined) {
                    newErrors[`grid-${key}`] = 'Selectează un răspuns';
                    hasErrors = true;
                }
            });
        }

        // Validate Part A true/false questions
        if (testData?.subiectI?.partA?.trueFalseQuestions) {
            testData.subiectI.partA.trueFalseQuestions.forEach(question => {
                const key = `partA-${question.id}`;
                if (userAnswers.trueFalse[key] === undefined) {
                    newErrors[`tf-${key}`] = 'Selectează un răspuns';
                    hasErrors = true;
                }
            });
        }

        // Validate Part A requirements
        if (testData?.subiectI?.partA?.requirements) {
            testData.subiectI.partA.requirements.forEach(req => {
                const key = `partA-requirement-${req.id}`;
                if (!userAnswers.textAnswers[key]?.trim()) {
                    newErrors[key] = 'Acest câmp este obligatoriu';
                    hasErrors = true;
                }
            });
        }

        // Validate Part B grid questions
        if (testData?.subiectI?.partB?.gridQuestions) {
            testData.subiectI.partB.gridQuestions.forEach(question => {
                const key = `partB-${question.id}`;
                if (userAnswers.grid[key] === undefined) {
                    newErrors[`grid-${key}`] = 'Selectează un răspuns';
                    hasErrors = true;
                }
            });
        }

        if (testData?.subiectI?.partB?.multipleAnswers) {
            testData.subiectI.partB.multipleAnswers.forEach(question => {
                // Verificăm dacă există cel puțin un răspuns pentru această întrebare
                const hasAnyAnswer = question.answers.some(answer => {
                    const key = `partB-${question.id}-${answer.id}`;
                    return userAnswers.multiAnswers[key]?.trim();
                });

                if (!hasAnyAnswer) {
                    newErrors[`multi-question-partB-${question.id}`] = 'Completează cel puțin un răspuns';
                    hasErrors = true;
                }
            });
        }

        // Validate Part B requirements
        if (testData?.subiectI?.partB?.requirements) {
            testData.subiectI.partB.requirements.forEach(req => {
                const key = `partB-requirement-${req.id}`;
                if (!userAnswers.textAnswers[key]?.trim()) {
                    newErrors[key] = 'Acest câmp este obligatoriu';
                    hasErrors = true;
                }
            });
        }

        // Validate Subiect II requirements
        if (testData?.subiectII?.requirements) {
            testData.subiectII.requirements.forEach(req => {
                const key = `subiectII-requirement-${req.id}`;
                if (!userAnswers.textAnswers[key]?.trim()) {
                    newErrors[key] = 'Acest câmp este obligatoriu';
                    hasErrors = true;
                }
            });
        }

        setErrors(newErrors);
        if (hasErrors) {
            setSaveMessage('Te rugăm să completezi toate răspunsurile obligatorii.');
            return;
        }

        setSaveMessage(null);
        setIsSaving(true);
        clearMutationError();

        try {
            // Prepare data for saving
            const testDataToSave = {
                subiectI: {
                    initialTexts: testData.subiectI.initialTexts,
                    partA: {
                        multipleAnswers: testData.subiectI.partA.multipleAnswers?.map(question => ({
                            id: question.id,
                            text: question.text,
                            title: question.title || '',
                            userAnswers: question.answers.map(answer => ({
                                id: answer.id,
                                correctAnswer: answer.text,
                                userAnswer: userAnswers.multiAnswers[`partA-${question.id}-${answer.id}`] || ''
                            }))
                        })),

                        // Grid questions
                        gridQuestions: testData.subiectI.partA.gridQuestions?.map(question => ({
                            id: question.id,
                            questionText: question.questionText,
                            correctAnswer: question.correctAnswer,
                            userAnswer: userAnswers.grid[`partA-${question.id}`] || null,
                            answers: {
                                answer1: question.answer1,
                                answer2: question.answer2,
                                answer3: question.answer3,
                                answer4: question.answer4
                            }
                        })),

                        // True/False questions
                        trueFalseQuestions: testData.subiectI.partA.trueFalseQuestions?.map(question => ({
                            id: question.id,
                            text: question.text,
                            isCorrect: question.isCorrect,
                            userAnswer: userAnswers.trueFalse[`partA-${question.id}`]
                        })),

                        // Requirements
                        requirements: testData.subiectI.partA.requirements?.map(req => ({
                            id: req.id,
                            text: req.text,
                            userAnswer: userAnswers.textAnswers[`partA-requirement-${req.id}`] || ''
                        }))
                    },
                    partB: {
                        // Grid questions
                        gridQuestions: testData.subiectI.partB.gridQuestions?.map(question => ({
                            id: question.id,
                            questionText: question.questionText,
                            correctAnswer: question.correctAnswer,
                            userAnswer: userAnswers.grid[`partB-${question.id}`] || null,
                            answers: {
                                answer1: question.answer1,
                                answer2: question.answer2,
                                answer3: question.answer3,
                                answer4: question.answer4
                            }
                        })),

                        multipleAnswers: testData.subiectI.partB.multipleAnswers?.map(question => ({
                            id: question.id,
                            text: question.text,
                            title: question.title || '',
                            userAnswers: question.answers.map(answer => ({
                                id: answer.id,
                                correctAnswer: answer.text,
                                userAnswer: userAnswers.multiAnswers[`partB-${question.id}-${answer.id}`] || ''
                            }))
                        })),

                        // Requirements
                        requirements: testData.subiectI.partB.requirements?.map(req => ({
                            id: req.id,
                            text: req.text,
                            userAnswer: userAnswers.textAnswers[`partB-requirement-${req.id}`] || ''
                        }))
                    }
                },
                subiectII: {
                    // Requirements
                    requirements: testData.subiectII.requirements?.map(req => ({
                        id: req.id,
                        text: req.text,
                        userAnswer: userAnswers.textAnswers[`subiectII-requirement-${req.id}`] || ''
                    }))
                },
                timestamp: new Date().toISOString()
            };

            const result = await saveTestData({
                product_sku: productSku,
                test_type: 'nationalEvaluation',
                test_data: JSON.stringify(testDataToSave),
                start_time: startTime || new Date().toISOString(),
                end_time: new Date().toISOString()
            });

            if (result?.success || result?.data?.saveCustomerTestData?.success) {
                setSaveMessage('Testul a fost salvat!');
                setTestCompleted(true);
            }
        } catch (error) {
            console.error('Eroare la salvarea răspunsurilor:', error);
            setSaveMessage('A apărut o eroare la salvarea răspunsurilor.');
        } finally {
            setIsSaving(false);
        }
    };

    if (!testData) {
        return <p className="text-red-500 p-4">
            {'Datele testului nu sunt disponibile.'}
        </p>;
    }

    return (
        <div className="national-evaluation-container bg-card-bg xl:w-[800px] w-full flex flex-col gap-3 mt-5 mb-10 md:mb-15
            p-3 md:p-6 border-2 border-double border-medium-blue rounded-xl
            bg-card-bg shadow-round-shadow border-x border-y">
            <div className="flex flex-col items-start justify-start gap-3">
                <Timer
                    maxTime={7200}
                    onStart={setStartTime}
                    isStarted={hasStarted}
                    onTimeExceeded={handleSubmit}
                />
                <h2 className="uppercase font-font-p tracking-wide font-semibold text-text-color text-2xl">
                    Evaluare națională
                </h2>
            </div>

            {/* Subiectul I - Texte inițiale */}
            <section className="mb-8">
                <h3 className="font-font-p tracking-wide font-semibold text-2xl text-text-color mb-3">
                    Subiectul I
                </h3>
                <p className="font-font-p tracking-wide font-semibold text-text-color text-2xl">
                    Citește cu atenție textul/textele de mai jos, pentru a putea răspunde la cerințele formulate:
                </p>
                {testData.subiectI.initialTexts?.map((text, idx) => (
                    <div key={text.id} className="mb-6">
                        <p className="font-font-p tracking-wide font-semibold text-text-color text-2xl">
                            Textul {idx + 1}
                        </p>
                        <div className="font-font-s font-normal !text-xl text-text-color bg-white p-3 border-2
                    border-double border-medium-blue border-x border-y"
                             dangerouslySetInnerHTML={{__html: text.text}}/>
                    </div>
                ))}
            </section>

            {/* Partea A */}
            <section className="mb-8">
                <h3 className="text-3xl font-bold text-amber-800 mb-6">Partea A</h3>

                {testData.subiectI.partA.multipleAnswers?.map((question, idx) => (
                    <div key={`multi-answer-partA-${question.id}`}
                         className="question w-full p-3 border-2 border-double border-medium-blue border-x border-y flex flex-col gap-3 mb-4">
                        <p className="font-font-s text-2xl font-bold">
                            {idx + 1}
                        </p>
                        <div
                            className="font-font-s font-normal text-text-color text-2xl bg-white p-3"
                            dangerouslySetInnerHTML={{__html: question.text}}
                        />

                        {question.title && (
                            <p className="font-font-s text-xl font-semibold mt-3 mb-2">
                                {question.title}
                            </p>
                        )}

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-3">
                            {/* Prima coloană - răspunsurile 1-3 */}
                            <div className="flex flex-col gap-4">
                                {question.answers.slice(0, 3).map((answer, index) => (
                                    <div key={`answer-partA-${question.id}-${answer.id}`} className="mb-2">
                                        <div className="flex flex-col">
                                            <label htmlFor={`multi-answer-partA-${question.id}-${answer.id}`}
                                                   className="font-font-s text-xl mb-1">
                                                {`${index + 1}(a)`}
                                            </label>
                                            <input
                                                id={`multi-answer-partA-${question.id}-${answer.id}`}
                                                type="text"
                                                className="border-2 border-double border-medium-blue p-2 w-full"
                                                value={userAnswers.multiAnswers[`partA-${question.id}-${answer.id}`] || ''}
                                                onChange={(e) => handleMultiAnswerChange(question.id, answer.id, e.target.value, 'partA')}
                                                placeholder="Răspunsul tău..."
                                            />
                                            {errors[`multi-question-partA-${question.id}`] && (
                                                <p className="text-red-500 font-font-s text-sm mt-1">
                                                    {errors[`multi-question-partA-${question.id}`]}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {/* A doua coloană - răspunsurile 4-6 */}
                            <div className="flex flex-col gap-4">
                                {question.answers.slice(3, 6).map((answer, index) => (
                                    <div key={`answer-partA-${question.id}-${answer.id}`} className="mb-2">
                                        <div className="flex flex-col">
                                            <label htmlFor={`multi-answer-partA-${question.id}-${answer.id}`}
                                                   className="font-font-s text-xl mb-1">
                                                {`${index + 1}(b)`}
                                            </label>
                                            <input
                                                id={`multi-answer-partA-${question.id}-${answer.id}`}
                                                type="text"
                                                className="border-2 border-double border-medium-blue p-2 w-full"
                                                value={userAnswers.multiAnswers[`partA-${question.id}-${answer.id}`] || ''}
                                                onChange={(e) => handleMultiAnswerChange(question.id, answer.id, e.target.value, 'partA')}
                                                placeholder="Răspunsul tău..."
                                            />
                                            {errors[`multi-question-partA-${question.id}`] && (
                                                <p className="text-red-500 font-font-s text-sm mt-1">
                                                    {errors[`multi-question-partA-${question.id}`]}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}

                {/* Grid Questions */}
                {testData.subiectI.partA.gridQuestions?.map((question, idx) => (
                    <div key={question.id}
                         className="question w-full p-3 border-2 border-double border-medium-blue border-x border-y flex flex-col gap-3 mb-4">
                        <p className="font-font-s text-2xl font-bold">
                            {idx + 2}
                        </p>
                        <p className="font-font-s font-normal text-text-color text-2xl bg-white p-3"
                           dangerouslySetInnerHTML={{__html: question.questionText}}/>
                        <div className="answers font-font-s font-normal text-text-color text-2xl flex flex-col gap-5">
                            {[
                                {id: 1, text: question.answer1},
                                {id: 2, text: question.answer2},
                                {id: 3, text: question.answer3},
                                {id: 4, text: question.answer4}
                            ].map((answer) => (
                                <label key={answer.id}
                                       className="w-full flex flex-row gap-1 items-center justify-start cursor-pointer">
                                    <input
                                        type="radio"
                                        className="border-2 border-gray-300 p-2 w:1/12"
                                        name={`question-partA-${question.id}`}
                                        checked={userAnswers.grid[`partA-${question.id}`] === answer.id}
                                        onChange={() => handleGridChange(question.id, answer.id, 'partA')}
                                    />
                                    <span dangerouslySetInnerHTML={{__html: answer.text}}
                                          className="font-font-s font-normal text-text-color text-2xl w:11/12"/>
                                </label>
                            ))}
                        </div>
                        {errors[`grid-partA-${question.id}`] && (
                            <p className="error-message text-red-500 font-font-s text-2xl">
                                {errors[`grid-partA-${question.id}`]}
                            </p>
                        )}
                    </div>
                ))}

                {/* True/False Questions */}
                {testData.subiectI.partA.trueFalseQuestions?.length > 0 && (
                    <div className="border-2 border-double border-medium-blue border-x border-y mb-4 p-3">
                        <p className="font-font-s text-2xl font-bold">
                            {'5'}
                        </p>
                        <p className="font-font-s font-normal text-xl text-text-color bg-white p-3
                            border-2 border-double border-medium-blue border-x border-y mb-4">
                            Bifează corectitudinea sau incorectitudinea fiecărui enunț, bazându-te pe informațiile din
                            text.
                        </p>

                        <div className="flex flex-col gap-4">
                            {testData.subiectI.partA.trueFalseQuestions.map((question,) => (
                                <div key={question.id}
                                     className="mb-4 border-2 p-3 border-double border-medium-blue border-x border-y">
                                    <p className="font-font-s text-xl mb-2 bg-white "
                                       dangerouslySetInnerHTML={{__html: question.text}}/>
                                    <div
                                        className="answers font-font-s font-normal text-text-color text-2xl flex flex-row gap-5">
                                        <label
                                            className="label font-font-s font-normal text-text-color text-2xl cursor-pointer">
                                            <input
                                                className="w-6 h-6 cursor-pointer"
                                                type="radio"
                                                checked={userAnswers.trueFalse[`partA-${question.id}`] === true}
                                                onChange={() => handleTrueFalseChange(question.id, true, 'partA')}
                                            />
                                            <span className="ml-2">Adevărat</span>
                                        </label>
                                        <label
                                            className="label font-font-s font-normal text-text-color text-2xl cursor-pointer">
                                            <input
                                                className="w-6 h-6 cursor-pointer"
                                                type="radio"
                                                checked={userAnswers.trueFalse[`partA-${question.id}`] === false}
                                                onChange={() => handleTrueFalseChange(question.id, false, 'partA')}
                                            />
                                            <span className="ml-2">Fals</span>
                                        </label>
                                    </div>
                                    {errors[`tf-partA-${question.id}`] && (
                                        <p className="error-message text-red-500 font-font-s text-2xl">
                                            {errors[`tf-partA-${question.id}`]}
                                        </p>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {/* Requirements */}
                {testData.subiectI.partA.requirements?.map((requirement, reqIndex) => (
                    <div key={requirement.id}
                         className="mb-4 border-2 border-double border-medium-blue border-x border-y p-4">
                        <p className="text-2xl font-semibold text-text-color mb-3">
                            {reqIndex + 6}
                        </p>
                        <div dangerouslySetInnerHTML={{__html: requirement.text}}
                             className="font-font-s font-normal text-xl text-text-color bg-white p-3
                        border-2 border-double border-medium-blue border-x border-y mb-4"/>
                        <textarea
                            name={`partA-requirement-${requirement.id}`}
                            className="border-2 border-double border-medium-blue border-x border-y w-full p-3"
                            placeholder="Răspunsul tău..."
                            rows={6}
                            value={userAnswers.textAnswers[`partA-requirement-${requirement.id}`] || ''}
                            onChange={(e) => handleRequirementChange(requirement.id, e.target.value, 'partA')}
                        />
                        {errors[`partA-requirement-${requirement.id}`] && (
                            <p className="error-message text-red-500 font-font-s text-xl">
                                {errors[`partA-requirement-${requirement.id}`]}
                            </p>
                        )}
                    </div>
                ))}
            </section>

            {/* Partea B */}
            <section className="mb-8">
                <h3 className="text-3xl font-bold text-amber-800 mb-6">Partea B</h3>

                {/* Grid Questions */}
                {testData.subiectI.partB.gridQuestions?.map((question, qIndex) => (
                    <div key={question.id}
                         className="question w-full p-3 border-2 border-double border-medium-blue border-x border-y flex flex-col gap-3 mb-4">
                        <p className="font-font-s text-2xl font-bold">
                            {qIndex + 1}
                        </p>
                        <p className="font-font-s font-normal text-text-color text-2xl bg-white p-3"
                           dangerouslySetInnerHTML={{__html: question.questionText}}/>
                        <div className="answers font-font-s font-normal text-text-color text-2xl flex flex-col gap-5">
                            {[
                                {id: 1, text: question.answer1},
                                {id: 2, text: question.answer2},
                                {id: 3, text: question.answer3},
                                {id: 4, text: question.answer4}
                            ].map((answer) => (
                                <label key={answer.id}
                                       className="w-full flex flex-row gap-1 items-center justify-start cursor-pointer">
                                    <input
                                        type="radio"
                                        className="border-2 border-gray-300 p-2 w:1/12"
                                        name={`question-partB-${question.id}`}
                                        checked={userAnswers.grid[`partB-${question.id}`] === answer.id}
                                        onChange={() => handleGridChange(question.id, answer.id, 'partB')}
                                    />
                                    <span dangerouslySetInnerHTML={{__html: answer.text}}
                                          className="font-font-s font-normal text-text-color text-2xl w:11/12"/>
                                </label>
                            ))}
                        </div>
                        {errors[`grid-partB-${question.id}`] && (
                            <p className="error-message text-red-500 font-font-s text-2xl">
                                {errors[`grid-partB-${question.id}`]}
                            </p>
                        )}
                    </div>
                ))}

                {testData.subiectI.partB.multipleAnswers?.map((question) => (
                    <div key={`multi-answer-partB-${question.id}`}
                         className="question w-full p-3 border-2 border-double border-medium-blue border-x border-y flex flex-col gap-3 mb-4">
                        <p className="font-font-s text-2xl font-bold">
                            {'5'}
                        </p>
                        <div
                            className="font-font-s font-normal text-text-color text-2xl bg-white p-3"
                            dangerouslySetInnerHTML={{__html: question.text}}
                        />

                        {question.title && (
                            <p className="font-font-s text-xl font-semibold mt-3 mb-2">
                                {question.title}
                            </p>
                        )}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-3">
                            {/* Prima coloană - răspunsurile 1-3 */}
                            <div className="flex flex-col gap-4">
                                {question.answers.slice(0, 3).map((answer, index) => (
                                    <div key={`answer-partB-${question.id}-${answer.id}`} className="mb-2">
                                        <div className="flex flex-col">
                                            <label htmlFor={`multi-answer-partB-${question.id}-${answer.id}`}
                                                   className="font-font-s text-xl mb-1">
                                                {`${index + 1}(a)`}
                                            </label>
                                            <input
                                                id={`multi-answer-partB-${question.id}-${answer.id}`}
                                                type="text"
                                                className="border-2 border-double border-medium-blue p-2 w-full"
                                                value={userAnswers.multiAnswers[`partB-${question.id}-${answer.id}`] || ''}
                                                onChange={(e) => handleMultiAnswerChange(question.id, answer.id, e.target.value, 'partB')}
                                                placeholder="Răspunsul tău..."
                                            />
                                            {errors[`multi-question-partB-${question.id}`] && (
                                                <p className="text-red-500 font-font-s text-sm mt-1">
                                                    {errors[`multi-question-partB-${question.id}`]}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>


                            {/* A doua coloană - răspunsurile 4-6 */}
                            <div className="flex flex-col gap-4">
                                {question.answers.slice(3, 6).map((answer, index) => (
                                    <div key={`answer-partB-${question.id}-${answer.id}`} className="mb-2">
                                        <div className="flex flex-col">
                                            <label htmlFor={`multi-answer-partB-${question.id}-${answer.id}`}
                                                   className="font-font-s text-xl mb-1">
                                                {`${index + 1}(b)`}
                                            </label>
                                            <input
                                                id={`multi-answer-partB-${question.id}-${answer.id}`}
                                                type="text"
                                                className="border-2 border-double border-medium-blue p-2 w-full"
                                                value={userAnswers.multiAnswers[`partB-${question.id}-${answer.id}`] || ''}
                                                onChange={(e) => handleMultiAnswerChange(question.id, answer.id, e.target.value, 'partB')}
                                                placeholder="Răspunsul tău..."
                                            />
                                            {errors[`multi-question-partB-${question.id}`] && (
                                                <p className="text-red-500 font-font-s text-sm mt-1">
                                                    {errors[`multi-question-partB-${question.id}`]}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}

                {/* Requirements */}
                {testData.subiectI.partB.requirements?.map((requirement, reqIndex) => (
                    <div key={requirement.id}
                         className="mb-4 border-2 border-double border-medium-blue border-x border-y p-4">
                        <p className="text-2xl font-semibold text-text-color mb-3">
                            {reqIndex + 6}
                        </p>
                        <div dangerouslySetInnerHTML={{__html: requirement.text}}
                             className="font-font-s font-normal text-xl text-text-color bg-white p-3
                        border-2 border-double border-medium-blue border-x border-y mb-4"/>
                        <textarea
                            name={`partB-requirement-${requirement.id}`}
                            className="border-2 border-double border-medium-blue border-x border-y w-full p-3"
                            placeholder="Răspunsul tău..."
                            rows={6}
                            value={userAnswers.textAnswers[`partB-requirement-${requirement.id}`] || ''}
                            onChange={(e) => handleRequirementChange(requirement.id, e.target.value, 'partB')}
                        />
                        {errors[`partB-requirement-${requirement.id}`] && (
                            <p className="error-message text-red-500 font-font-s text-xl">
                                {errors[`partB-requirement-${requirement.id}`]}
                            </p>
                        )}
                    </div>
                ))}
            </section>

            {/* Subiectul II */}
            <section className="mb-8">
                <h3 className="font-font-p tracking-wide font-semibold text-2xl text-text-color mb-3">
                    Subiectul al II-lea
                </h3>

                {/* Requirements */}
                {testData.subiectII.requirements?.map((requirement, reqIndex) => (
                    <div key={requirement.id}
                         className="mb-4 border-2 border-double border-medium-blue border-x border-y p-4">
                        <p className="font-font-s text-xl font-bold">
                            Cerința
                        </p>
                        <div dangerouslySetInnerHTML={{__html: requirement.text}}
                             className="font-font-s font-normal text-xl text-text-color bg-white p-3
                        border-2 border-double border-medium-blue border-x border-y mb-4"/>
                        <textarea
                            name={`subiectII-requirement-${requirement.id}`}
                            className="border-2 border-double border-medium-blue border-x border-y w-full p-3"
                            placeholder="Răspunsul tău..."
                            rows={10}
                            value={userAnswers.textAnswers[`subiectII-requirement-${requirement.id}`] || ''}
                            onChange={(e) => handleRequirementChange(requirement.id, e.target.value, 'subiectII')}
                        />
                        {errors[`subiectII-requirement-${requirement.id}`] && (
                            <p className="error-message text-red-500 font-font-s text-xl">
                                {errors[`subiectII-requirement-${requirement.id}`]}
                            </p>
                        )}
                    </div>
                ))}
            </section>

            {/* Submit button */}
            <div className="flex justify-center items-center flex-col">
                <div className="flex gap-4">
                    <SaveTestBtn
                        onClick={handleSubmit}
                        disabled={isSaving}
                        isSaving={isSaving}
                    />
                </div>
                {saveMessage &&
                    <p className="save-message mt-2 text-olive-color font-font-s text-2xl">{saveMessage}</p>}
                {mutationError && <ErrorMessage message={mutationError}/>}
            </div>
        </div>
    );
};
