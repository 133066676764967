import React from 'react';
import {FacebookIcon, InstagramIcon, YouTubeIcon} from '../../../../../general';

export const FooterLinks = () => {

    return (
        <div className={'w-full flex flex-col items-start justify-start'}>
            <div className="flex flex-row gap-10">
                <ul className={'flex flex-col gap-1 items-start justify-start'}>
                    <p className={'uppercase font-bold text-left text-sub-text font-font-s text-2xl mb-3'}>Informatii</p>
                    <li>
                        <a href={'/about-us'}
                            className={'text-left text-sub-text font-font-s text-2xl ' +
                                'hover:text-peru-color active:text-peru-color visited:text-sub-text transition-colors duration-300'}
                        >
                            {'Despre noi'}
                        </a>
                    </li>
                    <li>
                        <a href={'/contact'}
                           className={'text-left text-sub-text font-font-s text-2xl ' +
                               'hover:text-peru-color active:text-peru-color visited:text-sub-text transition-colors duration-300'}
                        >
                            {'Contacteaza-ne'}
                        </a>
                    </li>
                    <li>
                        <a href={'/privacy-policy-cookie-restriction-mode'}
                           className={'text-left text-sub-text font-font-s text-2xl ' +
                               'hover:text-peru-color active:text-peru-color visited:text-sub-text transition-colors duration-300'}
                        >
                            {'Politica de Confidențialitate'}
                        </a>
                    </li>
                    <li>
                        <a href={'/terms'}
                           className={'text-left text-sub-text font-font-s text-2xl ' +
                               'hover:text-peru-color active:text-peru-color visited:text-sub-text transition-colors duration-300'}
                        >
                            {'Termeni si conditii'}
                        </a>
                    </li>
                </ul>

            </div>
        </div>
    );
};
