import React from "react";
import {useProductAttributes} from "../../state";
import {PageTitle} from "./ProductInfo/PageTitle";
import {ShortDescription} from "./ProductInfo/ShortDescription";
import {InfoReviews} from "./ProductInfo/InfoReviews";
import {AddToFavoritesButton} from "./AddToCart/AddToFavoritesButton";
import {CustomAttributes} from "./ProductInfo/CustomAttributes";
import {AddToCartButton} from "./AddToCart/AddToCartButton";
import {PriceInfo} from "./ProductInfo/PriceInfo";
import {ProductImage} from "./ProductMedia/ProductImage";
import {VimeoVideo} from "./ProductMedia/VimeoVideo";

export const ProductInfo = ({productSku}) => {
    const {productAttributes} = useProductAttributes() || {};
    const hasVideo = productAttributes?.media_gallery?.[0]?.video_content?.video_url;
    return (
        <div className='w-full md:max-w-[1440px] flex flex-col justify-center items-center'>
            {hasVideo ? (
                <>
                    <VimeoVideo/>
                    <div className={'bg-card-bg shadow-round-shadow p-3 md:p-6 mb-5 md:mb-10 mt-5 md:mt-0 w-full rounded-xl'}>
                        <div className='flex flex-col md:flex-row gap-3 items-start justify-start'>
                            <div className={'p-5 flex flex-col justify-start gap-3 w-full md:w-2/4'}>
                                <PageTitle/>
                                <p className={'react-css font-font-p  tracking-wide text-xl text-center md:text-start md:text-start'}>
                                    {'Cod produs: '}{productSku}
                                </p>
                                <CustomAttributes/>
                                <div className={'p-5 flex flex-row items-center justify-center md:justify-start gap-10'}>
                                    <InfoReviews/>
                                    <AddToFavoritesButton productSku={productSku}/>
                                </div>
                            </div>
                            <div className='flex flex-col items-center justify-center w-full md:w-2/4'>
                                <ShortDescription/>
                            </div>
                        </div>

                        <PriceInfo/>
                        <AddToCartButton/>
                    </div>
                </>

            ) : (
                <div className={'bg-card-bg shadow-round-shadow p-3 md:p-6 mb-5 md:mb-10 mt-5 md:mt-0 w-full rounded-xl'}>
                    <div className='flex flex-col md:flex-row gap-3 items-start justify-start'>
                        <div className={'p-5 flex flex-col justify-start gap-3 w-full md:w-2/4'}>
                            <PageTitle/>
                            <p className={'react-css font-font-p  tracking-wide text-xl text-center md:text-start md:text-start'}>
                                {'Cod produs: '}{productSku}
                            </p>
                            <CustomAttributes/>
                            <div className={'p-5 flex flex-row items-center justify-center md:justify-start gap-10'}>
                                <InfoReviews/>
                                <AddToFavoritesButton productSku={productSku}/>
                            </div>
                        </div>
                        <div className='flex flex-col items-center justify-center w-full md:w-2/4'>
                            <ProductImage/>
                            <ShortDescription/>
                        </div>
                    </div>

                    <PriceInfo/>
                    <AddToCartButton/>
                </div>
            )}
        </div>
    );
}
