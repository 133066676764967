import React, {useMemo} from 'react';
import {useCategory} from '../../../state';
import {BlockSk} from "../assets/BlockSk";

function decodeHtml(html: string | null) {
    if (!html) return null;
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

export const CategoryData: React.FC = () => {
    const {categoryData, loading, error} = useCategory();

    if (loading) return <BlockSk/>;
    if (error) return <div>Error: {error.message}</div>;

    const decodedDescription = useMemo(() => {
        return decodeHtml(categoryData?.description);
    }, [categoryData?.description]);

    return (
        <>
            {categoryData && (
                <div className="flex flex-wrap flex-row md:flex-col justify-center items-start w-full">
                    {categoryData?.name && (
                        <h1 className="w-full text-2xl font-bold mb-4 w-full text-start">{categoryData?.name}</h1>
                    )}
                    <div className={'flex flex-col md:flex-row md:justify-start justify-center items-center gap-5 w-full'}>
                        {/*{categoryData?.image && (*/}
                        {/*    <div className="flex flex-wrap justify-center w-full md:w-1/4 max-h-[200px]">*/}
                        {/*        <img className={'w-auto h-[200px]'} src={categoryData?.image} alt={categoryData?.name}/>*/}
                        {/*    </div>*/}
                        {/*)}*/}
                        {decodedDescription && (
                            <div
                                className="w-full p-5 font-font-s text-text-color text-xl mb-6 w-full"
                                dangerouslySetInnerHTML={{__html: decodedDescription}}
                            />
                        )}
                    </div>

                </div>
            )}
        </>

    );
};
