import React, {createContext, useState, useContext, useEffect, ReactNode} from 'react';
import {request} from '../../../Infrastructure';
import {useMutation, useQuery} from "@tanstack/react-query";
import {
    getProductAttributes,
    saveCustomerTestData,
    getCustomAttributes,
    createProductReview,
    productReviewRatingsMetadata,
    getProductReviews,
    getProductViewConfig,
    getCustomerWishlistId,
    addProductsToWishlist,
    getAutoRelatedSkus,
    getAutoRelatedProducts,
    getLessonMaterial
} from '../../queries';

interface VideoContent {
    media_type: string;
    video_provider: string;
    video_url: string;
    video_title: string;
    video_description: string;
}

interface MediaGalleryItem {
    url: string;
    label: string;
    position: number;
    disabled: boolean;
    __typename?: string;
    video_content?: VideoContent;
}

interface ProductAttributes {
    id;
    sku;
    name;
    created_at;
    short_description?: { html: string };
    description?: { html: string };
    small_image?: { url: string; label: string; };
    media_gallery?: MediaGalleryItem[]
    categories?: {
        id: number;
        name: string;
        url_path: string;
    }[];
    special_text_promo?: string;
    text_from_date?: string;
    text_to_date?: string;
    url_rewrites: {
        url: string;
        parameters?: {
            name: string;
            value: string;
        }[];
    }[];
    price_range?: {
        minimum_price?: {
            regular_price: { value: number; currency: string };
            final_price: { value: number; currency: string };
            discount: { amount_off: number; percent_off: number }
        };
        maximum_price?: {
            regular_price: { value: number; currency: string };
            final_price: { value: number; currency: string }
        }
        discount?: {
            amount_off: { value: number; currency: string };
            percent_off: { value: number; currency: string }
        }
    };
}

interface CustomAttribute {
    attribute_code: string;
    value: string;
}

interface AutoRelatedSkus {
    id: string;
    sku: string;
    name: string;
}

interface AutoRelatedProducts {
    id;
    sku;
    name;
    type_id: string;
    short_description?: { html: string };
    small_image?: { label: string; };
    customProductImageUrl?: { url: string; };
    price_range: {
        minimum_price?: {
            regular_price: { value: number; currency: string };
            final_price: { value: number; currency: string };
            discount: { amount_off: number; percent_off: number }
        };
        maximum_price?: {
            regular_price: { value: number; currency: string };
            final_price: { value: number; currency: string }
        }
    };
    url_rewrites: {
        url: string;
        parameters?: {
            name: string;
            value: string;
        }[];
    }[];
    custom_attributes?: {
        attribute_code: string;
        value: string;
    }[];
    reviews?: {
        items?: {
            summary: string;
            text: string;
            nickname: string;
            created_at: string;
            average_rating: number;
            ratings_breakdown: {
                name: string;
                value: string;
            }[];
        }[];
        page_info: {
            total_pages: number;
        };
    };
}

interface SaveCustomerTestDataInput {
    product_sku: string;
    test_type: string;
    test_data: string;
    start_time: String
    end_time: String
}

declare global {
    interface Window {
        BASE_URL: string;
        Vimeo?: {
            Player: any;
        };
    }
}

//for product view page configuration
interface CategoryCustomerGroupMapping {
    category_id: number;
    customer_group_id: number;
}

interface ProductViewConfig {
    addToCartCategoryId: number | null;
    testsCategoryId: number | null;
    freeItemsCategoryId: number | null;
    lecturesCategoryId: number | null;
    categoryCustomerGroupMappings: CategoryCustomerGroupMapping[] | null;
}

//reviews
interface ProductFlags {
    isNew: boolean;
    isFree: boolean;
}

interface CreateProductReviewRequest {
    nickname: string;
    summary: string;
    text: string;
    ratings: {
        rating_name: string;
        rating_value: string;
    };
}

interface Review {
    summary: string;
    text: string;
    nickname: string;
    created_at: string;
    average_rating: number;
    ratings_breakdown: {
        name: string;
        value: string;
    }[];
}

//wishlist
interface AddToWishlistResponse {
    data: {
        addProductsToWishlist: {
            wishlist: {
                id: string;
                items_count: number;
            };
            user_errors: Array<any>;
        };
    };
}

type WishlistData = AddToWishlistResponse;

interface LessonMaterial {
    entity_id: number;
    product_id: number;
    product_sku: string;
    file_title: string;
    file_url: string | null;
    media_timestamp: string;
}

interface ProductAttributeContextValue {
    productSku: string;
    productAttributes: ProductAttributes | null;
    customAttributes: CustomAttribute[] | null;
    findCustomAttributeValue: (customAttributes: CustomAttribute[], attributeCode: string) => string | null;
    productViewConfig: ProductViewConfig | null;
    productDescription: string;
    productShortDescription: string;
    loading: boolean;
    error: any;
    productName: string;
    createdAt: string;
    categories: {
        id: number;
        name: string;
        url_path: string;
    }[] | null;
    mutationError: string | null;
    clearMutationError: () => void;
    handleAddReview: (review: any) => void;
    reviewRatings: any[];
    neededRating: any;
    productReviews: Review[];
    nextReviewsPage: () => void;
    prevReviewsPage: () => void;
    currentReviewPage: number;
    totalReviewPages: number;
    testData: any;
    saveTestData: (input: SaveCustomerTestDataInput) => Promise<any>;
    isTestCompleted: boolean;
    setTestCompleted: (completed: boolean) => void;
    isInTestCategory: boolean;
    isInFreeItemsCategory: boolean;
    isInAddToCartCategory: boolean;
    isLecturesCategory: boolean;
    addToWishlist: (productSku: string) => void;
    autoRelatedProducts: any[];
    pdfMaterial: LessonMaterial | null;
    productFlags: ProductFlags | null;
}

const ProductAttributeContext = createContext<ProductAttributeContextValue | undefined>(undefined);

export const ProductAttributeProvider: React.FC<{
    children: ReactNode,
    productSku: string,
    productFlags?: {
        isNew: boolean;
        isFree: boolean;
    }
}> = (
    {
        children,
        productSku,
        productFlags: initialProductFlags,
    }
) => {
    const [productAttributes, setProductAttributes] = useState<ProductAttributes | null>(null);
    const [customAttributes, setCustomAttributes] = useState<CustomAttribute[] | null>(null);
    const [productViewConfig, setProductViewConfig] = useState<ProductViewConfig | null>(null);
    const productName = productAttributes?.name || null;
    const createdAt = productAttributes?.created_at || null;
    const productDescription = productAttributes?.description?.html || '';
    const productShortDescription = productAttributes?.short_description?.html || '';
    const categories = productAttributes?.categories || null;
    const productId = productAttributes?.id || null;
    const [mutationError, setMutationError] = useState<string | null>(null);
    const [reviewRatings, setReviewRatings] = useState<any[]>([]);
    const [productReviews, setProductReviews] = useState<Review[]>([]);
    const [currentReviewPage, setCurrentReviewPage] = useState(1);
    const [totalReviewPages, setTotalReviewPages] = useState(1);
    const [wishlistData, setWishlistData] = useState<WishlistData | null>(null);
    const [wishlistId, setWishlistId] = useState<string | null>(null);
    const [autoRelatedSkus, setAutoRelatedSkus] = useState<AutoRelatedSkus[] | []>([]);
    const [autoRelatedProducts, setAutoRelatedProducts] = useState<AutoRelatedProducts[] | []>([]);
    const [productFlags, setProductFlags] = useState<ProductFlags | null>(initialProductFlags || null);

    const [isTestCompleted, setIsTestCompleted] = useState(false);
    const [isInTestCategory, setIsInTestCategory] = useState(false);
    const [isInFreeItemsCategory, setIsInFreeItemsCategory] = useState(false);
    const [isInAddToCartCategory, setIsInAddToCartCategory] = useState(false);
    const [isLecturesCategory, setIsLecturesCategory] = useState(false);
    const [pdfMaterial, setPdfMaterial] = useState<LessonMaterial | null>(null);

    const mappings = productViewConfig?.categoryCustomerGroupMappings;

    const PAGE_SIZE = 10;
    const qualityRating = 'Quality';
    const neededRating = reviewRatings.find(rating => rating.name === qualityRating);

    const testData = customAttributes?.find(attr => attr.attribute_code === 'test_data')?.value;

    const parsedTestData = (testData && testData !== "noSubscriptionAccess" && testData !== "userNotAuthenticated")
        ? JSON.parse(testData)
        : testData;

    const customAttributesQuery = useQuery({
        queryKey: ['customAttributes'],
        queryFn: async () => {
            return request(getCustomAttributes, {sku: productSku});
        },
        enabled: !!productSku,
        refetchOnWindowFocus: false,
    });

    const findCustomAttributeValue = (customAttributes, attributeCode) => {
        if (!customAttributes || customAttributes.length === 0) return null;

        const attribute = customAttributes.find(attr => attr && attr.attribute_code === attributeCode);
        return attribute ? attribute.value : null;
    };

    const productViewConfigQuery = useQuery({
        queryKey: ['productViewConfig'],
        queryFn: async () => {
            return request(getProductViewConfig);
        },
        enabled: true,
        refetchOnWindowFocus: false,
    });

    const productAttributesQuery = useQuery({
        queryKey: ['productAttributes'],
        queryFn: async () => {
            return request(getProductAttributes, {sku: productSku});
        },
        enabled: !!productSku,
        refetchOnWindowFocus: false,
    });

    const productReviewRatingsQuery = useQuery({
        queryKey: ['reviewRatings'],
        queryFn: async () => {
            return request(productReviewRatingsMetadata);
        },
        enabled: true,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            setReviewRatings(data.data.productReviewRatingsMetadata.items);
        },
    });

    const productReviewsQuery = useQuery({
        queryKey: ['productReviews', productSku, currentReviewPage],
        queryFn: async () => {
            return request(getProductReviews, {
                sku: productSku,
                pageSize: PAGE_SIZE,
                currentPage: currentReviewPage
            });
        },
        enabled: !!productSku,
        refetchOnWindowFocus: false,
    });

    const autoRelatedSkusQuery = useQuery({
        queryKey: ['autoRelatedSkus', productSku],
        queryFn: async () => {
            const variables = {sku: productSku};
            return request(getAutoRelatedSkus, variables);
        },
        enabled: productSku !== null,
        refetchOnWindowFocus: false,
    });

    const autoRelatedProductsQuery = useQuery({
        queryKey: ['autoRelatedProducts', autoRelatedSkus],
        queryFn: async () => {
            const variables = {
                skus: autoRelatedSkus,
                pageSize: 500,
                currentPage: 1
            };
            return request(getAutoRelatedProducts, variables);
        },
        enabled: autoRelatedSkus?.length > 0,
        refetchOnWindowFocus: false,
    });

    const pdfMaterialQuery = useQuery({
        queryKey: ['lectureMaterial', productId],
        queryFn: async () => {
            const variables = {productId: productId};
            return request(getLessonMaterial, variables);
        },
        enabled: productId !== null,
        refetchOnWindowFocus: false,
    });

    const saveTestDataMutation = useMutation({
        mutationFn: (input: SaveCustomerTestDataInput) =>
            request(saveCustomerTestData, {input}),
    });

    const saveTestData = async (input: SaveCustomerTestDataInput) => {
        try {
            if (typeof input.test_data !== 'string') {
                input.test_data = JSON.stringify(input.test_data);
            }
            const result = await saveTestDataMutation.mutateAsync(input);

            // Verificăm dacă eroarea este pentru tipul necunoscut
            if (result.errors && result.errors.length > 0) {
                // Dacă eroarea este despre tipul necunoscut, continuăm procesul
                if (result.errors[0].extensions?.debugMessage?.includes("Tip de test necunoscut")) {
                    setMutationError(null);
                    setIsTestCompleted(true);
                    return { success: true }; // Returnăm un obiect fake de succes
                }

                // Pentru orice altă eroare, o afișăm
                setMutationError(result.errors[0].message);
                return null;
            }

            if (result.data && result.data.saveCustomerTestData === null) {
                setMutationError("Eroare la salvarea datelor testului.");
                return null;
            }

            setMutationError(null);
            setIsTestCompleted(true);
            return result.data.saveCustomerTestData;
        } catch (error) {
            console.error("Error saving test data:", error);
            setMutationError("A apărut o eroare neașteptată.");
            throw error;
        }
    };

    const setTestCompleted = (completed: boolean) => {
        setIsTestCompleted(completed);
    };

    const clearMutationError = () => setMutationError(null);

    //wishlist
    const customerWishlistQuery = useQuery({
        queryKey: ['customerWishlist'],
        queryFn: async () => {
            return request(getCustomerWishlistId);
        },
        enabled: true,
        refetchOnWindowFocus: false,
    });

    const addToWishlistMutation = useMutation<AddToWishlistResponse, Error, string>({
        mutationFn: async (sku: string) => {
            if (!wishlistId) {
                window.location.href = '/customer/account/login/';
                throw new Error("User not authenticated");
            }
            return request(addProductsToWishlist, {
                wishlistId,
                wishlistItems: [{ sku, quantity: 1 }]
            });
        },
        onSuccess: (data) => {
            window.location.href = '/wishlist/';
            setWishlistData(data);
        },
        onError: (error: Error) => {
            if (error.message !== "User not authenticated") {
                console.error('Error adding product to wishlist:', error);
            }
        },
    });

    const addToWishlist = (productSku: string) => {
        addToWishlistMutation.mutate(productSku);
    };
    //end wishlist

    useEffect(() => {
        // If initialProductFlags are provided, use them
        if (initialProductFlags) {
            setProductFlags(initialProductFlags);
        }
    }, [initialProductFlags]);

    useEffect(() => {
        if (customAttributesQuery?.data) {
            setCustomAttributes(customAttributesQuery?.data?.data?.products?.items[0]?.custom_attributes);
        }

        if (productViewConfigQuery.data) {
            setProductViewConfig(productViewConfigQuery.data.data.productViewConfig);
        }

        if (productAttributesQuery?.data) {
            setProductAttributes(productAttributesQuery?.data?.data?.products?.items[0]);
        }

        if (productReviewRatingsQuery?.data) {
            setReviewRatings(productReviewRatingsQuery?.data?.data?.productReviewRatingsMetadata?.items);
        }

        if (productReviewsQuery.data) {
            const reviewsData = productReviewsQuery.data.data.products.items[0]?.reviews;
            setProductReviews(reviewsData?.items || []);
            setTotalReviewPages(reviewsData?.page_info.total_pages || 1);
        }

        if (customerWishlistQuery?.data?.data?.customer?.wishlist) {
            setWishlistId(customerWishlistQuery.data.data.customer.wishlist.id);
        }

        if (autoRelatedSkusQuery?.data) {
            const skus = autoRelatedSkusQuery?.data?.data?.getAutoRelatedSkus.map(product => product.sku);
            setAutoRelatedSkus(skus);
        }

        if (autoRelatedProductsQuery?.data) {
            setAutoRelatedProducts(autoRelatedProductsQuery?.data?.data?.products?.items);
        }

        if (pdfMaterialQuery?.data) {
            setPdfMaterial(pdfMaterialQuery?.data?.data.getLessonMaterial);
        }

    }, [
        productAttributesQuery.isSuccess,
        productViewConfigQuery.isSuccess,
        customAttributesQuery.isSuccess,
        productReviewRatingsQuery.isSuccess,
        productReviewsQuery.isSuccess,
        customerWishlistQuery.data,
        autoRelatedSkusQuery.data,
        autoRelatedProductsQuery.data,
        pdfMaterialQuery.data,
    ]);

    useEffect(() => {
        if (productAttributes && productViewConfig) {
            const productCategories = productAttributes.categories || [];
            const testsCategoryId = productViewConfig.testsCategoryId;
            const freeItemsCategoryId = productViewConfig.freeItemsCategoryId;
            const addToCartCategoryId = productViewConfig.addToCartCategoryId;
            const lecturesCategoryId = productViewConfig.lecturesCategoryId;

            const isInTest = productCategories.some(category => category.id === testsCategoryId);
            const isInFree = productCategories.some(category => category.id === freeItemsCategoryId);
            const isInAddToCart = productCategories.some(category => category.id === addToCartCategoryId);
            const isInLectures = productCategories.some(category => category.id === lecturesCategoryId);

            setIsInTestCategory(isInTest);
            setIsInFreeItemsCategory(isInFree);
            setIsInAddToCartCategory(isInAddToCart);
            setIsLecturesCategory(isInLectures);
        }
    }, [productAttributes, productViewConfig]);

    const nextReviewsPage = () => {
        if (currentReviewPage < totalReviewPages) {
            setCurrentReviewPage(prevPage => prevPage + 1);
        }
    };

    const prevReviewsPage = () => {
        if (currentReviewPage > 1) {
            setCurrentReviewPage(prevPage => prevPage - 1);
        }
    };

    //reviews
    const mutation = useMutation((input: CreateProductReviewRequest) =>
        request(createProductReview, {input})
    );

    const handleAddReview = (review: any) => {
        mutation.mutate(review);
    }

    return (
        <ProductAttributeContext.Provider value={
            {
                productSku,
                productName,
                createdAt,
                productDescription,
                productShortDescription,
                productAttributes,
                customAttributes,
                findCustomAttributeValue,
                productViewConfig,
                loading: productAttributesQuery.isLoading ||
                    saveTestDataMutation.isLoading ||
                    customAttributesQuery.isLoading ||
                    productViewConfigQuery.isLoading ||
                    productReviewRatingsQuery.isLoading ||
                    productReviewsQuery.isLoading ||
                    customerWishlistQuery.isLoading ||
                    addToWishlistMutation.isLoading,
                error: productAttributesQuery.error ||
                    saveTestDataMutation.error ||
                    customAttributesQuery.error ||
                    productViewConfigQuery.error ||
                    productReviewRatingsQuery.error ||
                    productReviewsQuery.error ||
                    customerWishlistQuery.error ||
                    addToWishlistMutation.error,
                categories,
                mutationError,
                clearMutationError,
                handleAddReview,
                reviewRatings,
                neededRating,
                productReviews,
                nextReviewsPage,
                prevReviewsPage,
                currentReviewPage,
                totalReviewPages,
                testData: parsedTestData,
                isTestCompleted,
                setTestCompleted,
                saveTestData,
                isInTestCategory,
                isInFreeItemsCategory,
                isInAddToCartCategory,
                isLecturesCategory,
                addToWishlist,
                autoRelatedProducts,
                pdfMaterial,
                productFlags
            }
        }>
            {children}
        </ProductAttributeContext.Provider>
    );
};

export const useProductAttributes = () => {
    const context = useContext(ProductAttributeContext);
    if (!context) {
        throw new Error('useProductAttributes must be used within a ProductAttributeProvider');
    }
    return context;
};
