import React from 'react';
import {TrueOrFalseTestView} from './TrueOrFalseTestView';
import {GridTestView} from './GridTestView';
import {MultipleAnswerTestView} from './MultipleAnswerTestView';
import {UnderConstruction} from "../../../../StaticPages";
import {NationalEvaluationTestView} from "./NationalEvaluationTestView";
import {AlertTriangle} from "lucide-react";

export const TestDetailsView = ({testData, onBack, testTime}) => {
    if (!testData) {
        return <div>No test data available</div>;
    }

    const renderTestContent = () => {
        const parsedData = JSON.parse(testData.test_data);

        switch (testData.test_type) {
            case 'trueOrFalse':
                return <TrueOrFalseTestView data={parsedData}/>;
            case 'gridTest':
                return <GridTestView data={parsedData}/>;
            case 'multiple_answer':
                return <MultipleAnswerTestView data={parsedData}/>;
            case 'nationalEvaluation':
                // Adăugăm scorul în obiectul de date pentru a-l putea afișa în componenta NationalEvaluationTestView
                try {
                    const scoreData = JSON.parse(testData.test_score);
                    return <NationalEvaluationTestView data={{...parsedData, score_details: scoreData.test_score}}/>;
                } catch (e) {
                    console.error("Error parsing score data:", e);
                    return <NationalEvaluationTestView data={parsedData}/>;
                }
            default:
                return (
                    <div>
                        <p>Inca nu s-a implementat acest tip de test</p>
                        <UnderConstruction/>
                    </div>
                )
        }
    };

    const getCorrectionMessage = (correctionType) => {
        switch (correctionType) {
            case 'auto':
                return (
                    <p className="text-font-s text-custom-black text-2xl">
                        Acest test a fost evaluat automat de sistem, comparând răspunsurile tale cu răspunsurile corecte.
                    </p>
                );
            case 'ai_evaluation':
                return (
                    <p className="text-font-s text-custom-black text-2xl">
                        Acest test a fost evaluat de inteligența artificială. Testul poate fi reevaluat de un profesor.
                    </p>
                );
            case 'pro_evaluation':
                return (
                    <p className="text-font-s text-custom-black text-2xl">
                        Acest test a fost reevaluat de către un profesor.
                    </p>
                );
            default:
                return (
                    <p className="text-font-s text-custom-black text-2xl">
                        Acest test nu a fost evaluat.
                    </p>
                );
        }
    };

    const renderScoreDetails = (testScore, testType) => {
        if (!testScore) return null;

        try {
            const parsedScore = JSON.parse(testScore);

            // Afișare diferită pentru Evaluarea Națională
            if (testType === 'nationalEvaluation') {
                const neScore = parsedScore.test_score;
                return (
                    <div className="score-details p-4 bg-card-bg xl:w-[1000px] border border-gray-300 rounded-lg">
                        <h2 className="text-xl font-bold mb-3">Detalii punctaj</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                                <p><strong>Subiectul I.A: </strong>
                                    <span className="text-wood-color text-2xl font-bold">
                                        {neScore.subiect1A.score}/{neScore.subiect1A.max_score}
                                    </span>
                                    </p>
                                <p><strong>Subiectul I.B: </strong>
                                    <span className="text-wood-color text-2xl font-bold">
                                        {neScore.subiect1B.score}/{neScore.subiect1B.max_score}
                                    </span>
                                    </p>
                            </div>
                            <div>
                                <p><strong>Subiectul II: </strong>
                                    <span className="text-wood-color text-2xl font-bold">
                                        {neScore.subiect2.score}/{neScore.subiect2.max_score}
                                    </span>
                                    </p>
                                <p><strong>Puncte din oficiu:</strong> {neScore.puncte_oficiu} </p>
                            </div>
                        </div>
                        <div className="mt-4 pt-4 border-t border-gray-300">
                            <p><strong>Total cerințe: </strong>
                                <span className="text-peru-color text-2xl font-bold">
                                    {neScore.total_questions || 'N/A'}
                                </span>
                                </p>
                            <p><strong>Răspunsuri corecte: </strong>
                                <span className="text-peru-color text-2xl font-bold">
                                    {neScore.correct_answers || 'N/A'}
                                </span>
                                </p>
                            <p className="text-xl font-bold mt-2">
                                <strong>Punctaj total: </strong>
                                <span className="text-olive-color text-4xl font-bold">
                                    {neScore.total.score}/{neScore.total.max_score}
                                </span>
                            </p>
                        </div>
                        <p className="mt-2"><strong>Timp de rezolvare:</strong>
                            <span className="text-peru-color text-2xl font-bold">
                                {testTime}
                            </span>
                            </p>

                        <div className="p-4 bg-amber-50 border border-amber-200 text-amber-800">
                            <div className="flex items-start gap-3">
                                <AlertTriangle className="w-5 h-5 mt-1 flex-shrink-0 text-amber-500" />
                                <div>
                                    <h4 className="font-medium mb-2">Notă importantă privind evaluarea</h4>
                                    <p className="text-xl leading-relaxed">
                                        În acest moment, din păcate, nu vă putem estima răspunsurile pentru compuneri.
                                        Acestea sunt evaluate automat la un punctaj de 80% din punctajul maxim acordat.
                                        Lucrăm la implementarea unei soluții de corectare AI. După implementare, veți avea și
                                        opțiunea să solicitați evaluarea acestor tipuri de teste și de către unul dintre profesorii noștri.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }

            // Afișare standard pentru alte tipuri de teste
            return (
                <div className="score-details p-4 bg-card-bg xl:w-[1000px] border border-gray-300 rounded-lg">
                    <h2 className="text-xl font-bold mb-3">Detalii punctaj</h2>
                    <p><strong>Total cerințe: </strong>
                        <span className="text-peru-color text-2xl font-bold">
                            {parsedScore.test_score.total_questions}
                        </span>
                        </p>
                    <p><strong>Răspunsuri corecte: </strong>
                        <span className="text-peru-color text-2xl font-bold">
                            {parsedScore.test_score.correct_answers}
                        </span>
                        </p>
                    <p><strong>Răspunsuri greșite: </strong>
                        <span className="text-peru-color text-2xl font-bold">
                            {parsedScore.test_score.incorrect_answers}
                        </span>
                        </p>
                    <p><strong>Punctaj total: </strong>
                        <span className="text-olive-color text-4xl font-bold">
                            {parsedScore.test_score.total_points || parsedScore.test_score.score_percentage}
                        </span>
                        </p>
                    <p><strong>Timp de rezolvare: </strong>
                        <span className="text-peru-color text-2xl font-bold">
                            {testTime}
                        </span>
                        </p>
                </div>
            );
        } catch (e) {
            console.error("Error parsing score:", e);
            return (
                <div className="score-details p-4 bg-card-bg xl:w-[1000px] border border-gray-300 rounded-lg">
                    <h2 className="text-xl font-bold mb-3">Detalii punctaj</h2>
                    <p>Eroare la încărcarea detaliilor de punctaj.</p>
                    <p><strong>Timp de rezolvare:</strong>
                        <span className="text-peru-color text-2xl font-bold">
                            {testTime}
                        </span>
                        </p>
                </div>
            );
        }
    };

    const correctionMessage = getCorrectionMessage(testData.correction_type);

    return (
        <div className="test-details-view text-font-s">
            <div className="flex flex-col items-start gap-3 mb-4">
                <h1 className="text-2xl font-bold mb-2 text-font-s">Detalii Test</h1>
                <p className="text-font-s"><strong>Cod Test:</strong> {testData.test_code}</p>
                <p className="text-font-s">
                    <strong>Data:</strong> {new Date(testData.created_at).toLocaleDateString()}</p>
                <div className="flex flex-row gap-2 p-4 bg-amber-50 border border-amber-200 text-amber-800">
                    <AlertTriangle className="w-5 h-5 mt-1 flex-shrink-0 text-amber-500" />
                    {correctionMessage}
                </div>
            </div>

            {renderScoreDetails(testData.test_score, testData.test_type)}

            <button
                onClick={onBack}
                className="mt-4 px-4 py-2 bg-wood-color text-white hover:bg-[#A0522D]"
            >
                Înapoi la lista de teste
            </button>

            {renderTestContent()}

            <button
                onClick={onBack}
                className="mt-4 px-4 py-2 bg-wood-color text-white hover:bg-[#A0522D]"
            >
                Înapoi la lista de teste
            </button>
        </div>
    );
};
