import React from 'react';

export const CategoryCards: React.FC<{ categories: any[] }> = ({ categories }) => {
    if (!categories || categories.length === 0) return <p>Nu există categorii de afișat.</p>;

    const getWidthClass = (count: number): string => {
        switch(count) {
            case 1: return 'w-full';
            case 2: return 'w-1/2';
            case 3: return 'w-1/3';
            default: return 'w-1/4';
        }
    };

    const widthClass = getWidthClass(categories.length);

    const getBackgroundImageUrl = (category: any) => {
        return category.image ? `url(${category.image})` : 'none';
    };

    return (
        <div className="flex flex-wrap md:flex-nowrap justify-center w-full">
            {categories.map((category, index) => {

                const isLastItem = index === categories.length - 1;
                const isOddNumber = categories.length % 2 !== 0;
                const mobileWidthClass = isLastItem && isOddNumber ? 'w-full' : 'w-1/2';

                return (
                <div key={category.id} className={`md:${widthClass} ${mobileWidthClass}`}>
                    <a
                        href={`${window.BASE_URL}${category.url_path}.html`}
                        className="block h-[400px] w-full relative shadow-md overflow-hidden"
                        placeholder={`Vezi testele pentru ${category.name}`}
                    >
                        <div
                            className="w-full h-full bg-cover bg-center bg-card-bg border border-gray-200"
                            style={{
                                backgroundImage: getBackgroundImageUrl(category),
                            }}
                        />
                        <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 p-4 h-1/5">
                            <p className="text-4xl md:text-5xl font-bold text-center text-white font-font-p">
                                {category.name}
                            </p>
                        </div>
                    </a>
                </div>
                );
            })}
        </div>
    );
};
