import React from 'react';

export const NationalEvaluationTestView = ({ data }) => {
    // Verificăm dacă avem date valide
    if (!data) return <div>Nu există date disponibile pentru acest test.</div>;

    const { subiectI, subiectII } = data;

    return (
        <div className="national-evaluation bg-card-bg xl:w-[800px] w-full flex flex-col gap-3 mt-5 mb-10 md:mb-15 p-3 md:p-6 border border-double border-medium-blue shadow-round-shadow border-x border-y">
            <h2 className="uppercase font-font-p tracking-wide font-semibold text-text-color text-2xl">
                Evaluare Națională - Rezultate
            </h2>

            {/* Textele inițiale */}
            {subiectI?.initialTexts && (
                <section className="initial-texts mb-8">
                    <h2 className={'font-font-p tracking-wide font-semibold text-2xl text-text-color mb-3'}>
                        Subiectul I
                    </h2>
                    {subiectI.initialTexts.map((text) => (
                        <div key={text.id} className={'flex flex-col gap-3 mb-3'}>
                            <p className="font-font-p tracking-wide font-semibold text-text-color text-2xl">
                                Textul {text.id}
                            </p>
                            <p
                                dangerouslySetInnerHTML={{ __html: text.text }}
                                className={'font-font-s font-normal text-text-color text-2xl bg-white p-3 ' +
                                    'border border-double border-medium-blue border-x border-y'}
                            />
                        </div>
                    ))}
                </section>
            )}

            {/* Partea A */}
            {subiectI?.partA && (
                <section className="partA mb-8">
                    <h2 className="text-3xl font-bold text-amber-800 mb-6">Partea A</h2>

                    {/* MultipleAnswers */}
                    {subiectI.partA.multipleAnswers?.map((item) => (
                        <div key={item.id} className="mb-8">
                            <div className="flex flex-col gap-3 border border-double border-medium-blue border-x border-y p-3 mb-4">
                                <h3 className="text-2xl font-semibold mb-4 text-wood-color">1</h3>
                                <div className="bg-white p-3">
                                    <p
                                        dangerouslySetInnerHTML={{ __html: item.text }}
                                        className="font-font-s font-normal text-text-color text-2xl"
                                    />
                                </div>
                                <div className="mt-4 bg-gray-50 p-4">
                                    <h3 className="font-semibold text-gray-700">Răspunsurile tale:</h3>
                                    {item.userAnswers.map((answer, idx) => {
                                        if (!answer.userAnswer) return null;
                                        return (
                                            <div key={idx} className="flex items-start gap-2 mt-2">
                                                <span className="font-medium">{idx + 1}:</span>
                                                <span className={`${answer.correctAnswer === answer.userAnswer ? 'text-green-600' : 'text-red-600'}`}>
                                                    {answer.userAnswer}
                                                </span>
                                                {answer.correctAnswer && (
                                                    <div className="ml-4">
                                                        <span className="font-medium">Răspuns corect:</span>
                                                        <span className="text-green-600 ml-2">{answer.correctAnswer}</span>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    ))}

                    {/* Grid Questions */}
                    {subiectI.partA.gridQuestions?.map((question, index) => (
                        <div key={question.id} className="flex flex-col gap-3 border border-double border-medium-blue border-x border-y p-3 mb-8">
                            <h3 className="text-2xl font-semibold mb-4 text-wood-color">{index + 2}</h3>
                            <p className="font-font-s text-xl font-bold">
                                Cerința
                            </p>
                            <div className="bg-white p-3">
                                <p
                                    dangerouslySetInnerHTML={{ __html: question.questionText }}
                                    className="font-font-s font-normal text-text-color text-2xl"
                                />
                            </div>
                            <div className="mt-4 bg-gray-50 p-4">
                                <div className="flex items-start gap-2">
                                    <span className="font-semibold text-gray-700">Răspunsul tău:</span>
                                    <span className={`${question.userAnswer === question.correctAnswer ? 'text-green-600' : 'text-red-600'}`}>
                                        {question.answers[`answer${question.userAnswer}`] || 'Niciun răspuns selectat'}
                                    </span>
                                </div>
                                <div className="flex items-start gap-2 mt-2">
                                    <span className="font-semibold text-gray-700">Răspuns corect:</span>
                                    <span className="text-green-600">{question.answers[`answer${question.correctAnswer}`]}</span>
                                </div>
                            </div>
                        </div>
                    ))}

                    {/* True/False Questions */}
                    {subiectI.partA.trueFalseQuestions && subiectI.partA.trueFalseQuestions.length > 0 && (
                        <div className="flex flex-col gap-3 w-full border border-double border-medium-blue border-x border-y p-3 mb-8">
                            <h3 className="text-2xl font-semibold mb-4 text-wood-color">5</h3>
                            {subiectI.partA.trueFalseQuestions.map((question) => (
                                <div key={question.id} className="flex flex-col gap-3 mb-8">
                                    <div className="bg-white p-3">
                                        <p
                                            dangerouslySetInnerHTML={{ __html: question.text }}
                                            className="font-font-s font-normal text-text-color text-2xl"
                                        />
                                    </div>
                                    <div className="mt-4 bg-gray-50 p-4">
                                        <div className="flex items-start gap-2">
                                            <span className="font-semibold text-gray-700">Răspunsul tău:</span>
                                            <span className={`${question.userAnswer === question.isCorrect ? 'text-green-600' : 'text-red-600'}`}>
                                                {question.userAnswer ? 'Adevărat' : 'Fals'}
                                            </span>
                                        </div>
                                        <div className="flex items-start gap-2 mt-2">
                                            <span className="font-semibold text-gray-700">Răspuns corect:</span>
                                            <span className="text-green-600">{question.isCorrect ? 'Adevărat' : 'Fals'}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    {/* Requirements */}
                    {subiectI.partA.requirements?.map((requirement, index) => (
                        <div key={requirement.id} className="border border-double border-medium-blue border-x border-y p-3 mb-8">
                            <h3 className="text-2xl font-semibold mb-4 text-wood-color">
                                {index + 6}
                            </h3>
                            <p className="font-font-s text-xl font-bold">
                                Cerința
                            </p>
                            <div className="bg-white p-3">
                                <p
                                    dangerouslySetInnerHTML={{ __html: requirement.text }}
                                    className="font-font-s font-normal text-text-color text-2xl"
                                />
                            </div>
                            <div className="mt-4 bg-gray-50 p-4">
                                <h3 className="font-semibold text-gray-700">Răspunsul tău:</h3>
                                <p className="mt-2 text-gray-600 text-2xl">{requirement.userAnswer || 'Niciun răspuns oferit'}</p>
                            </div>
                        </div>
                    ))}
                </section>
            )}

            {/* Partea B - Structură similară cu Partea A */}
            {subiectI?.partB && (
                <section className="partB mb-8">
                    <h2 className="text-3xl font-bold text-amber-800 mb-6">Partea B</h2>

                    {/* Grid Questions */}
                    {subiectI.partB.gridQuestions?.map((question, index) => (
                        <div key={question.id} className="flex flex-col gap-3 border border-double border-medium-blue border-x border-y p-3 mb-8">
                            <h3 className="text-2xl font-semibold mb-4 text-wood-color">
                                {index + 1}
                            </h3>
                            <p className="font-font-s text-xl font-bold">
                                Cerința
                            </p>
                            <div className="bg-white p-3">
                                <p
                                    dangerouslySetInnerHTML={{ __html: question.questionText }}
                                    className="font-font-s font-normal text-text-color text-2xl"
                                />
                            </div>
                            <div className="mt-4 bg-gray-50 p-4">
                                <div className="flex items-start gap-2">
                                    <span className="font-semibold text-gray-700">Răspunsul tău:</span>
                                    <span className={`${question.userAnswer === question.correctAnswer ? 'text-green-600' : 'text-red-600'}`}>
                                        {question.answers[`answer${question.userAnswer}`] || 'Niciun răspuns selectat'}
                                    </span>
                                </div>
                                <div className="flex items-start gap-2 mt-2">
                                    <span className="font-semibold text-gray-700">Răspuns corect:</span>
                                    <span className="text-green-600">{question.answers[`answer${question.correctAnswer}`]}</span>
                                </div>
                            </div>
                        </div>
                    ))}

                    {/* Multiple Answers */}
                    {subiectI.partB.multipleAnswers?.map((item) => (
                        <div key={item.id} className="mb-8">
                            <div className="flex flex-col gap-3 border border-double border-medium-blue border-x border-y p-3 mb-4">
                                <h3 className="text-2xl font-semibold mb-4 text-wood-color">5</h3>
                                <div className="bg-white p-3">
                                    <p
                                        dangerouslySetInnerHTML={{ __html: item.text }}
                                        className="font-font-s font-normal text-text-color text-2xl"
                                    />
                                </div>
                                <div className="mt-4 bg-gray-50 p-4">
                                    <h3 className="font-semibold text-gray-700">Răspunsurile tale:</h3>
                                    {item.userAnswers.map((answer, idx) => {
                                        if (!answer.userAnswer) return null;
                                        return (
                                            <div key={idx} className="flex items-start gap-2 mt-2">
                                                <span className="font-medium">{idx + 1}:</span>
                                                <span className={`${answer.correctAnswer === answer.userAnswer ? 'text-green-600' : 'text-red-600'}`}>
                                                    {answer.userAnswer}
                                                </span>
                                                {answer.correctAnswer && (
                                                    <div className="ml-4">
                                                        <span className="font-medium">Răspuns corect:</span>
                                                        <span className="text-green-600 ml-2">{answer.correctAnswer}</span>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    ))}

                    {/* Requirements */}
                    {subiectI.partB.requirements?.map((requirement, index) => (
                        <div key={requirement.id} className="border border-double border-medium-blue border-x border-y p-3 mb-8">
                            <p className="text-2xl font-semibold mb-4 text-wood-color">
                                {index + 6}
                            </p>
                            <p className="font-font-s text-xl font-bold">
                                Cerința
                            </p>
                            <div className="bg-white p-3">
                                <p
                                    dangerouslySetInnerHTML={{ __html: requirement.text }}
                                    className="font-font-s font-normal text-text-color text-2xl"
                                />
                            </div>
                            <div className="mt-4 bg-gray-50 p-4">
                                <h3 className="font-semibold text-gray-700">Răspunsul tău:</h3>
                                <p className="mt-2 text-gray-600 text-2xl">{requirement.userAnswer || 'Niciun răspuns oferit'}</p>
                            </div>
                        </div>
                    ))}
                </section>
            )}

            {/* Subiectul II */}
            {subiectII?.requirements && (
                <section className="subiect2 mb-8">
                    <h2 className={'font-font-p tracking-wide text-2xl font-semibold mb-4 text-wood-color'}>
                        Subiectul al II-lea
                    </h2>
                    {subiectII.requirements.map((requirement) => (
                        <div key={requirement.id} className="space-y-4">
                            <div className="bg-white p-3 border border-double border-medium-blue rounded">
                                <p
                                    dangerouslySetInnerHTML={{ __html: requirement.text }}
                                    className="font-font-s font-normal text-text-color text-2xl"
                                />
                            </div>
                            <div className="bg-gray-50 p-4 rounded">
                                <div className="mb-4">
                                    <h3 className="font-semibold text-gray-700">Răspunsul tău:</h3>
                                    <p className="mt-2 text-gray-600 text-2xl">{requirement.userAnswer || 'Niciun răspuns oferit'}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </section>
            )}
        </div>
    );
};
