import React, {useMemo} from 'react';
import {useProductAttributes} from '../../../state';
import {useWindowSize, CardItem, LoadingIcon} from "../../../../../general";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
import {Navigation} from 'swiper/modules';

export const AutoRelated = () => {
    const {autoRelatedProducts, isInAddToCartCategory, loading, productFlags} = useProductAttributes() || {};
    const {isMobile, isTablet} = useWindowSize();
    const itemsToShow = isMobile ? 2 : isTablet ? 4 : 5;
    const gapBetween = isMobile ? 0 : 0;

    if (loading) {
        return null;
    }

    if (!autoRelatedProducts.length) return null;

    if (autoRelatedProducts.length > 0) {
        return (
            <div className={'bg-card-bg react-css mt-10 w-full shadow-round-shadow p-4 xl:p-8 rounded-xl'}>
                <h2 className={' react-css uppercase font-font-p  tracking-wide tracking-wider font-semibold leading-[40px] leading-[50px] ' +
                    'font-text-color text-2xl xl:pl-10 xl:pr-10 xl:w-full ' +
                    ' z-[2] pl-5 '}>
                    {'Similare'}
                </h2>
                <div className={' react-css w-full '}>
                    <Swiper navigation={false}
                            modules={[Navigation]}
                            slidesPerView={itemsToShow}
                            spaceBetween={gapBetween}
                            freeMode={true}
                            className={'react-slider'}
                    >
                        {autoRelatedProducts.map((product) => (
                            <SwiperSlide key={product.id}
                                         className={'px-1 py-2'}
                            >
                                <CardItem product={product} isInAddToCartCategory={isInAddToCartCategory} productFlags={productFlags}/>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        );
    }

};
