import React, {useState} from 'react';
import {X} from 'lucide-react';
import {TrueFalseTestAdminView} from "./TrueFalseTestAdminView";
import {NationalEvaluationTestAdminView} from "./NationalEvaluationTestAdminView";
import {GridTestAdminView} from "./GridTestAdminView";
import {MultipleAnswerTestAdminView} from "./MultipleAnswerTestAdminView";
import {useAdminTestData} from "../../../../state";

export const TestViewModal = ({isOpen, onClose, testData}) => {
    const {getProcessedTestData} = useAdminTestData();
    const processedData = getProcessedTestData(testData.test_data);

    if (!isOpen) return null;

    const TestComponentMap = {
        trueOrFalse: TrueFalseTestAdminView,
        multiple_answer: MultipleAnswerTestAdminView,
        gridTest: GridTestAdminView,
        nationalEvaluation: NationalEvaluationTestAdminView
    };

    const TestComponent = TestComponentMap[testData.test_type];

    const renderScoreDetails = (testScore) => {
        if (!testScore) return null;

        const parsedScore = JSON.parse(testScore);

        return (
            <div className="score-details p-4 bg-card-bg xl:w-[800px] border border-gray-300 rounded-lg">
                <h2 className="text-xl font-bold mb-3">Detalii punctaj</h2>
                <p><strong>Total cerinte:</strong> {parsedScore.test_score.total_questions}</p>
                <p><strong>Răspunsuri corecte:</strong> {parsedScore.test_score.correct_answers}</p>
                <p><strong>Răspunsuri greșite:</strong> {parsedScore.test_score.incorrect_answers}</p>
                <p><strong>Punctaj total:</strong> {parsedScore.test_score.total_points}</p>
            </div>
        );
    };

    return (
        <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex">
            <div className="relative p-6 bg-white w-full w-7xl xl:w-[1100px] m-auto  ">
                <div className="flex justify-between items-center mb-4 border-b pb-4">
                    <div>
                        <h2 className="text-xl font-semibold">{testData.test_code}</h2>
                        <div className="text-xl text-gray-500 mt-1">
                            User: {testData.customer_email}
                        </div>
                        {renderScoreDetails(testData.test_score)}
                    </div>
                </div>
                <div className="max-h-[70vh] overflow-y-auto">
                    {TestComponent ?
                        <TestComponent testData={processedData}/> :
                        <p>Unsupported test type: {testData.test_type}</p>
                    }
                </div>
                <button
                    onClick={onClose}
                    className="p-2 hover:bg-gray-100 rounded-full absolute top-[20px] right-[20px]"
                >
                    <X className="h-5 w-5"/>
                </button>
            </div>
        </div>
    );
};
