import React from 'react';

export const AdvantagesSection = () => {
    return (
        <div className="w-full flex flex-col items-center bg-palette-dark-background/80 text-palette-dark-primary">
            <div className="w-full flex flex-col items-center text-palette-dark-primary max-w-[1440px] p-10">
                <h2 className="text-6xl font-bold text-center md:text-left mb-5">Ce îți oferim?</h2>
                <div className="space-y-6 w-full max-w-[1000px]">
                    {[
                        "Acces la lecții structurate clar, ușor de urmărit",
                        "Exemple practice care te ajută să înțelegi mai bine materia",
                        "Teste interactive pentru a-ți verifica cunoștințele",
                        "Posibilitatea de a învăța în ritmul tău, fără presiune",
                        "Explicații simple și directe, fără teorie inutilă"
                    ].map((text, index) => (
                        <div
                            key={index}
                            className="bg-palette-dark-secondary p-6 rounded-lg flex items-center transition-transform transform hover:translate-x-2 hover:shadow-lg hover:bg-palette-dark-tertiary"
                        >
                            <div
                                className="w-12 h-12 min-w-[48px] bg-palette-dark-accent text-text-color flex items-center justify-center rounded-md mr-6 font-bold text-3xl"
                            >
                                {index + 1}
                            </div>
                            <p className="text-3xl text-white text-center md:text-left">{text}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
