import React, {createContext, useContext, useState, useEffect, ReactNode} from 'react';
import {useQuery, useMutation, useQueryClient} from "@tanstack/react-query";
import {request} from "../../../Infrastructure";
import {
    getAdminNationalEvaluationData,
    saveNationalEvaluationPart
} from '../../queries';

interface GridQuestion {
    id: number;
    questionText: string;
    answer1: string | null;
    answer2: string | null;
    answer3: string | null;
    answer4: string | null;
    correctAnswer: number;
}

export interface NationalEvaluationData {
    product_id: number;
    productSku: string;
    title: string;
    status: number;
    createdAt: string;
    updatedAt: string;
    subiectI: {
        initialTexts: Array<{
            id: number;
            text: string;
        }>;
        partA: {
            multipleAnswers: {
                id: number;
                text: string;
                title: string;
                answers: Array<{
                    id: number;
                    text: string;
                }>;
            };
            gridQuestions: Array<GridQuestion>;
            trueFalseQuestions: Array<{
                id: number;
                text: string;
                answer: boolean;
            }>;
            requirements: Array<{
                id: number;
                text: string;
            }>;
        };
        partB: {
            multipleAnswers: {
                id: number;
                text: string;
                title: string;
                answers: Array<{
                    id: number;
                    text: string;
                }>;
            };
            gridQuestions: Array<GridQuestion>;
            trueFalseQuestions: Array<{
                id: number;
                text: string;
                answer: boolean;
            }>;
            requirements: Array<{
                id: number;
                text: string;
            }>;
        };
    };
    subiectII: {
        requirements: Array<{
            id: number;
            text: string;
        }>;
    };
}

interface NationalEvaluationContextProps {
    evaluationData: NationalEvaluationData | null;
    loading: boolean;
    error: Error | unknown;
    saveInitialTexts: (data: any) => Promise<void>;
    savePartAMultipleAnswers: (data: any) => Promise<void>;
    savePartAGrid: (data: any) => Promise<void>;
    savePartATrueFalse: (data: any) => Promise<void>;
    savePartARequirements: (data: any) => Promise<void>;
    savePartBGrid: (data: any) => Promise<void>;
    savePartBMultipleAnswers: (data: any) => Promise<void>;
    savePartBRequirements: (data: any) => Promise<void>;
    saveSub2Requirements: (data: any) => Promise<void>;
}

const NationalEvaluationContext = createContext<NationalEvaluationContextProps | undefined>(undefined);

export const NationalEvaluationProvider: React.FC<{ children: ReactNode; product_id: number, productSku: string }> =
    ({
         children,
         product_id,
         productSku
     }) => {
        const queryClient = useQueryClient();
        const [evaluationData, setEvaluationData] = useState<NationalEvaluationData | null>(null);

        const evaluationQuery = useQuery({
            queryKey: ['nationalEvaluation', product_id],
            queryFn: async () => {
                const response = await request(getAdminNationalEvaluationData, {
                    product_id: product_id
                });
                return response.data;
            },
            enabled: !!product_id,
            refetchOnWindowFocus: false,
        });

        const mutation = useMutation({
            mutationFn: async (input: {
                type: string,
                data: any
            }) => {
                return request(saveNationalEvaluationPart, {
                    input: {
                        product_id,
                        productSku,
                        type: input.type,
                        // Trimitem direct array-ul fără data wrapper
                        initialTexts: input.type === 'initial_texts' ? input.data : undefined,
                        gridQuestions: ['parta_grid', 'partb_grid'].includes(input.type) ? input.data : undefined,
                        requirements: ['parta_requirements', 'partb_requirements', 'sub2_requirements'].includes(input.type) ? input.data : undefined,
                        trueFalseQuestions: input.type === 'parta_true_false' ? input.data : undefined,
                        multipleAnswers: ['parta_multiple_answers', 'partb_multiple_answers'].includes(input.type) ? input.data : undefined,
                    }
                });
            },
            onSuccess: () => {
                queryClient.invalidateQueries({queryKey: ['nationalEvaluation', product_id]});
            },
            onError: (error) => {
                console.error('Failed to save national evaluation part:', error);
            },
        });

        const saveTestPart = async (type: string, data: any) => {
            await mutation.mutateAsync({type, data});
        };

        const saveInitialTexts = async (data: Array<{ id: number, text: string }>) => {
            return saveTestPart('initial_texts', data);
        };

        const savePartAGrid = async (data: Array<{
            id: number;
            questionText: string;
            answer1: string | null;
            answer2: string | null;
            answer3: string | null;
            answer4: string | null;
            correctAnswer: number | null;
        }>) => {
            return saveTestPart('parta_grid', data.map(question => ({
                id: question.id,
                questionText: question.questionText,
                answer1: question.answer1 || '',
                answer2: question.answer2 || '',
                answer3: question.answer3 || '',
                answer4: question.answer4 || '',
                correctAnswer: Number(question.correctAnswer)
            })));
        };

        const savePartBGrid = async (data: Array<{
            id: number;
            questionText: string;
            answer1: string | null;
            answer2: string | null;
            answer3: string | null;
            answer4: string | null;
            correctAnswer: number | null;
        }>) => {
            return saveTestPart('partb_grid', data.map(question => ({
                id: question.id,
                questionText: question.questionText,
                answer1: question.answer1 || '',
                answer2: question.answer2 || '',
                answer3: question.answer3 || '',
                answer4: question.answer4 || '',
                correctAnswer: Number(question.correctAnswer)
            })));
        };

        const savePartATrueFalse = async (data: Array<{
            id: number;
            text: string;
            isCorrect: boolean;
        }>) => {
            return saveTestPart('parta_true_false', data.map(question => ({
                id: question.id,
                text: question.text,
                isCorrect: question.isCorrect
            })));
        };

        const savePartARequirements = async (data: any) => {
            return saveTestPart('parta_requirements', data);
        };

        const savePartAMultipleAnswers = async (data: {
            id: number;
            text: string;
            title: string;
            answers: Array<{
                id: number;
                text: string;
            }>;
        }) => {
            // Învelim datele într-un array pentru a corespunde cu schema GraphQL
            const formattedData = [{
                id: data.id,
                text: data.text,
                title: data.title,
                answers: data.answers.map(answer => ({
                    id: answer.id,
                    text: answer.text
                }))
            }];

            return saveTestPart('parta_multiple_answers', formattedData);
        };

        const savePartBMultipleAnswers = async (data: {
            id: number;
            text: string;
            title: string;
            answers: Array<{
                id: number;
                text: string;
            }>;
        }) => {
            const formattedData = [{
                id: data.id,
                text: data.text,
                title: data.title,
                answers: data.answers.map(answer => ({
                    id: answer.id,
                    text: answer.text
                }))
            }];

            return saveTestPart('partb_multiple_answers', formattedData);
        };

        const savePartBRequirements = async (data: any) => {
            return saveTestPart('partb_requirements', data);
        };

        const saveSub2Requirements = async (data: any) => {
            return saveTestPart('sub2_requirements', data);
        };

        useEffect(() => {
            if (evaluationQuery.data) {
                setEvaluationData(evaluationQuery.data.getAdminNationalEvaluationData);
            }
        }, [evaluationQuery.data]);

        return (
            <NationalEvaluationContext.Provider value={{
                evaluationData,
                loading: mutation.isLoading || evaluationQuery.isLoading,
                error: mutation.error || evaluationQuery.error,
                saveInitialTexts,
                savePartAGrid,
                savePartBGrid,
                savePartATrueFalse,
                savePartARequirements,
                savePartBRequirements,
                saveSub2Requirements,
                savePartAMultipleAnswers,
                savePartBMultipleAnswers,
            }}>
                {children}
            </NationalEvaluationContext.Provider>
        );
    };

export const useNationalEvaluation = (): NationalEvaluationContextProps => {
    const context = useContext(NationalEvaluationContext);
    if (!context) {
        throw new Error('useNationalEvaluation must be used within a NationalEvaluationProvider');
    }
    return context;
};
