import React from 'react';
import {useProductAttributes} from '../../../state';
import {VideoSk} from "../Skeleton/VideoSk";
import {VideoLoginPrompt} from "../ProductDetails/assets/VideoLoginPrompt";
import {VideoSubscriptionRequired} from "../ProductDetails/assets/VideoSubscriptionRequired";
import {VimeoVideoPreview} from "./VimeoVideoPreview";

export const VimeoVideo = ({ isPreview = false }) => {
    const {productAttributes, loading, isLecturesCategory, testData} = useProductAttributes() || {};

    const mediaGallery = productAttributes?.media_gallery?.[0];
    const videoContent = mediaGallery?.video_content;

    if (loading) {
        return <VideoSk />;
    }

    if (!videoContent?.video_url) {
        return null;
    }

    const getVimeoId = (url: string): string | null => {
        const vimeoRegex = /(?:vimeo\.com)\/(?:.*?\/)?(?:.*?)(\d+)/;
        const match = url?.match(vimeoRegex);
        return match ? match[1] : null;
    };

    const vimeoId = getVimeoId(videoContent.video_url);

    if (!vimeoId) {
        return null;
    }

    if (isPreview) {
        return <VimeoVideoPreview vimeoId={vimeoId} />;
    }

    if (isLecturesCategory) {
        if (testData === 'userNotAuthenticated') {
            return <VideoLoginPrompt vimeoId={vimeoId}/>;
        }

        if (testData === 'noSubscriptionAccess') {
            return <VideoSubscriptionRequired vimeoId={vimeoId}/>;
        }
    }

    return (
        <div className="w-full p-2 md:p-8">
            <iframe
                src={`https://player.vimeo.com/video/${vimeoId}?quality=1080p`}
                className="w-full relative top-0 left-0 h-[200px] md:h-[600px]"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
            />
        </div>
    );
};
