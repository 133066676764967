import React from 'react';

export const SearchLinksCards = () => {
    const basePath = '/static/frontend/Hint4/theme/ro_RO/images/';

    const searchLinks = [
        { id: 'morfologie', name: 'Morfologie', url: '/catalogsearch/result/?q=morfologie', image: `${basePath}morfologie.png` },
        { id: 'sintaxa', name: 'Sintaxă', url: '/catalogsearch/result/?q=sintaxa', image: `${basePath}sintaxa.png` },
        { id: 'fonetica', name: 'Fonetică', url: '/catalogsearch/result/?q=fonetica', image: `${basePath}fonetica.png` },
        { id: 'vocabular', name: 'Vocabular', url: '/catalogsearch/result/?q=vocabular', image: `${basePath}vocabular.png` },
        { id: 'scriereCorecta', name: 'Scriere corectă', url: '/catalogsearch/result/?q=scriere%20corecta', image: `${basePath}scriereCorecta.png` },
    ];

    const getWidthClass = (count) => {
        switch (count) {
            case 1:
                return 'w-full';
            case 2:
                return 'w-1/2';
            case 3:
                return 'w-1/3';
            default:
                return 'w-1/4';
        }
    };

    const widthClass = getWidthClass(searchLinks.length);

    return (
        <div className="flex flex-wrap md:flex-nowrap justify-center w-full">
            {searchLinks.map((link, index) => {
                const isLastItem = index === searchLinks.length - 1;
                const isOddNumber = searchLinks.length % 2 !== 0;
                const mobileWidthClass = isLastItem && isOddNumber ? 'w-full' : 'w-1/2';

                return (
                    <div key={link.id} className={`md:${widthClass} ${mobileWidthClass}`}>
                        <a
                            href={link.url}
                            className="block h-[400px] w-full relative shadow-md overflow-hidden"
                        >
                            <div
                                className="w-full h-full bg-cover bg-center bg-card-bg border border-gray-200"
                                style={{backgroundImage: `url(${link.image})`}}
                            />
                            <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 p-4 h-1/5">
                                <p className="text-4xl md:text-5xl font-bold text-center text-white font-font-p">
                                    {link.name}
                                </p>
                            </div>
                        </a>
                    </div>
                );
            })}
        </div>
    );
};
