import React from 'react';

export const FeaturesSection = () => {
    return (
        <div className="w-full flex flex-col items-center bg-lime-700/80 mt-1">
            <div className="w-full flex flex-col items-center p-10 max-w-[1440px]">
                <h2 className="text-6xl font-font-p font-bold text-center mb-6 text-white">
                    Descoperă Resursele Noastre!
                </h2>
                <div className="space-y-6 w-full max-w-[1000px]">
                    {[{
                        title: "Mai Mult Decât Simple Teste",
                        text: "Oferim acces la teste și materiale educaționale adaptate special pentru tine și modul tău de învățare."
                    },
                        {
                            title: "Pentru Toate Nivelurile",
                            text: "Ai acces la teste gratuite pentru toate nivelurile de învățământ, de la clasa a V-a până la clasa a VIII-a. Pregătim și teste speciale pentru Evaluarea Națională, exact cum cere programa școlară."
                        },
                        {
                            title: "Urmărește-ți Progresul",
                            text: "În contul tău vei găsi un istoric complet al testelor tale. Așa poți vedea unde te descurci cel mai bine și ce mai trebuie să exersezi."
                        },
                        {
                            title: "Abonamente și Resurse",
                            text: "În curând vei putea accesa și mai multe resurse prin sistemul nostru de abonamente. Pregătim materiale noi în fiecare săptămână!"
                        }
                    ].map((feature, index) => (
                        <div key={index}
                             className="bg-white p-6 rounded-lg shadow-md border-l-4 border-green-700 transition-transform transform hover:translate-x-2">
                            <h3 className="text-3xl text-center md:text-left font-font-p font-bold mb-2 text-text-color">{feature.title}</h3>
                            <p className="text-3xl text-center md:text-left font-font-s">{feature.text}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
