import React from 'react';
import { AlertCircle, Play, ArrowRight } from 'lucide-react';
import {VimeoVideoPreview} from "../../ProductMedia/VimeoVideoPreview";

export const VideoSubscriptionRequired = ({vimeoId}) => {
    const subscriptionUrl = `${window.BASE_URL}subscriptii.html`;

    return (
        <div className="w-full flex flex-col items-center justify-center p-4 md:p-8">
            <div className="bg-card-bg shadow-round-shadow flex flex-col items-center justify-center p-8 w-full md:w-1/2 rounded-xl">
                <div className="mb-6 w-full h-48 md:h-64 rounded-lg flex items-center justify-center overflow-hidden">
                    <VimeoVideoPreview vimeoId={vimeoId} />
                </div>

                <div className="flex items-center justify-center mb-6">
                    <AlertCircle className="w-12 h-12 text-yellow-500 mr-4 animate-bounce" />
                    <Play className="w-10 h-10 text-gray-400" />
                </div>

                <h2 className="text-2xl font-bold font-font-p text-gray-800 mb-4">
                    🎥 Conținut premium
                </h2>

                <p className="text-xl text-center font-font-s text-gray-700 mb-6">
                    Această lecție video face parte dintr-un capitol special pentru abonați! 💖
                </p>

                <div className="flex flex-col items-center">
                    <p className="text-xl font-semibold text-center font-font-s text-text-color mb-6">
                        Alege abonamentul potrivit pentru clasa ta și: <br/>
                        🎥 Accesează toate lecțiile video disponibile din programul tău de abonat <br/>
                        📚 Parcurge materialele pas cu pas <br/>
                        🌟 Învață în ritmul tău
                    </p>

                    <a href={subscriptionUrl} className="no-underline hover:no-underline">
                        <button className="bg-olive-color hover:bg-olive-color text-white font-bold font-font-p py-3 px-6
                            flex items-center gap-2 hover:text-white transition duration-300 ease-in-out
                            transform hover:scale-105 rounded-full">
                            Vezi abonamentele disponibile
                            <ArrowRight className="w-5 h-5" />
                        </button>
                    </a>
                </div>
            </div>
        </div>
    );
};
