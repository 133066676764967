import React from 'react';
import {useProductAttributes} from "../../../state";
import {ImageSk} from "../Skeleton/ImageSk";

export const ProductImage = () => {
    const {productAttributes, loading} = useProductAttributes() || {};

    const fallbackImage = productAttributes?.small_image?.url;
    const imageLabel = productAttributes?.small_image?.label || 'Product image';

    if (!fallbackImage) {
        return null;
    }

    return (
        <div className="w-full flex flex-col justify-center items-center">
            <img
                src={fallbackImage}
                alt={imageLabel}
                className="w-11/12 object-contain object-center max-h-[110px] md:max-h-[150px] text-sm aspect-square"
            />
        </div>
    );
};
