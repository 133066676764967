import React, {useState, useMemo} from 'react';
import {useProductAttributes, useRoTestDataProductProvider} from '../../../../state';
import {TestResult} from "../assets/TestResult";
import {SaveTestBtn, ErrorMessage} from "../../../../../../general";
import {DescriptionSk} from "../../Skeleton/DescriptionSk";
import {Timer} from "../assets/Timer";

function decodeHtml(html: string) {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

export const TrueOrFalse = () => {
    const {
        productSku,
    } = useProductAttributes() || {};

    const {
        roTrueFalseTestData,
        setTestCompleted,
        saveTestData,
        mutationError,
        clearMutationError,
        isTestCompleted,
        testScore,
        loading,
        error,
    } = useRoTestDataProductProvider();

    const [userAnswers, setUserAnswers] = useState({});
    const [saveMessage, setSaveMessage] = useState<string | null>(null);
    const [isSaving, setIsSaving] = useState(false);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const [startTime, setStartTime] = useState<string | null>(null);
    const [hasStarted, setHasStarted] = useState(false);

    const decodedTestData = useMemo(() => {
        if (!roTrueFalseTestData?.trueFalseQuestions) return null;

        const shuffledQuestions = [...roTrueFalseTestData.trueFalseQuestions].sort(() => Math.random() - 0.5);

        return {
            ...roTrueFalseTestData,
            trueFalseQuestions: shuffledQuestions.map(question => ({
                ...question,
                questionText: decodeHtml(question.questionText),
            })),
        };
    }, [roTrueFalseTestData]);

    const handleAnswerChange = (questionId, value) => {
        if (!hasStarted) {
            setHasStarted(true);
        }

        setUserAnswers(prev => ({
            ...prev,
            [questionId]: value,
        }));

        if (errors[questionId]) {
            setErrors(prev => {
                const newErrors = { ...prev };
                delete newErrors[questionId];
                return newErrors;
            });
        }
    };

    const handleSave = async () => {
        const newErrors = {};

        // Verifică dacă toate întrebările au răspuns
        decodedTestData.trueFalseQuestions.forEach(question => {
            if (userAnswers[question.question_id] === undefined) {
                newErrors[question.question_id] = 'Selectează un răspuns';
            }
        });

        setErrors(newErrors);

        if (Object.keys(newErrors).length > 0) {
            setSaveMessage('Te rugăm să răspunzi la toate întrebările înainte de a finaliza testul.');
            return;
        }

        setSaveMessage(null);
        setIsSaving(true);
        clearMutationError();

        try {
            // Sortează întrebările după `question_id` înainte de salvare
            const sortedQuestions = [...decodedTestData.trueFalseQuestions].sort((a, b) => a.question_id - b.question_id);

            const userTestData = sortedQuestions.map(question => ({
                question_id: question.question_id,
                question_text: question.questionText,
                user_answer: userAnswers[question.question_id],
                correct_answer: question.correctAnswer,
            }));

            const testDataToSave = {
                trueOrFalse: userTestData,
                timestamp: new Date().toISOString()
            };

            const result = await saveTestData({
                product_sku: productSku,
                test_type: 'trueOrFalse',
                test_data: JSON.stringify(testDataToSave),
                start_time: startTime,
                end_time: new Date().toISOString(),
            });

            if (result?.data?.saveCustomerTestData?.success) {
                setSaveMessage('Testul a fost salvat!');
                setTestCompleted(true);
            }

        } catch (error) {
            console.error('Eroare la salvarea răspunsurilor:', error);
            setSaveMessage('A apărut o eroare la salvarea răspunsurilor.');
        } finally {
            setIsSaving(false);
        }
    };


    if (loading) {
        return <DescriptionSk/>;
    }

    if (error) return <p>Eroare la încărcarea testului.</p>;
    if (!decodedTestData?.trueFalseQuestions?.length) return null;

    if (isTestCompleted && testScore?.test_score) {
        return <TestResult score={testScore} saveMessage={saveMessage} />;
    }

    return (
        <div
            className="true-or-false-container bg-card-bg xl:w-[800px] w-full flex flex-col gap-3 mt-5 mb-10 md:mb-15
                p-3 md:p-6 border-2 border-double border-medium-blue rounded-xl
                bg-card-bg shadow-round-shadow border-x border-y"
        >
            <div className="flex flex-col items-start justify-start gap-3">
                <Timer
                    maxTime={1800}
                    onStart={setStartTime}
                    isStarted={hasStarted}
                    onTimeExceeded={handleSave}
                />
                <h2 className="uppercase font-font-p  tracking-wide font-semibold text-text-color text-2xl">
                    Adevarat sau fals
                </h2>
                <p className="font-font-p  tracking-wide font-semibold text-text-color text-2xl">
                    Citeste cu atentie textul/textele de mai jos, pentru a putea raspunde la cerintele formulate:
                </p>
            </div>
            {decodedTestData.trueFalseQuestions.map((question, index) => (
                <div key={question.question_id}
                     className="question w-full p-3 border-2 border-double border-medium-blue border-x border-y flex flex-col gap-3">
                    <p className="font-font-s text-2xl font-bold">
                        Cerinta {index + 1}
                    </p>
                    <p className="font-font-s font-normal text-text-color text-2xl bg-white p-3"
                       dangerouslySetInnerHTML={{__html: question.questionText}}/>
                    <div className="answers font-font-s font-normal text-text-color text-2xl flex flex-row gap-5">
                        <label className="label font-font-s font-normal text-text-color text-2xl cursor-pointer">
                            <input
                                className="w-6 h-6 cursor-pointer"
                                type="radio"
                                checked={userAnswers[question.question_id] === true}
                                onChange={() => handleAnswerChange(question.question_id, true)}
                            />
                            Adevărat
                        </label>
                        <label className="label font-font-s font-normal text-text-color text-2xl cursor-pointer">
                            <input
                                className="w-6 h-6 cursor-pointer"
                                type="radio"
                                checked={userAnswers[question.question_id] === false}
                                onChange={() => handleAnswerChange(question.question_id, false)}
                            />
                            Fals
                        </label>
                    </div>
                    {errors[question.question_id] && (
                        <p className="error-message text-red-500 font-font-s text-2xl">
                            {errors[question.question_id]}
                        </p>
                    )}
                </div>
            ))}
            <div className="flex justify-center items-center flex-col">
                <div className="flex gap-4">
                    <SaveTestBtn
                        onClick={handleSave}
                        disabled={Object.keys(errors).length > 0}
                        isSaving={isSaving}
                    />
                </div>
                {saveMessage && <p className="save-message mt-2 text-olive-color font-font-s text-2xl">{saveMessage}</p>}
                {mutationError && <ErrorMessage message={mutationError}/>}
            </div>
        </div>
    );
};
