import React from 'react';
import {useHomepageContextProvider} from '../../state';
import {CategoryCards} from "./assets/CategoryCards";
import {CustomMarquee} from './assets/CustomMarquee';
import {VideoLinks} from "./assets/VideoLinks";
import {AdvantagesSection} from "./assets/AdvantagesSection";
import {FeaturesSection} from "./assets/FeaturesSection";
import {DevelopmentNotice} from "./assets/DevelopmentNotice";
import {SearchLinksCards} from "./assets/SearchLinksCards";

export const HomeMainContent: React.FC = () => {
    const {categoriesData, loading, homepageData} = useHomepageContextProvider();

    const getSelectedCategories = (ids: string[] | undefined) => {
        if (!categoriesData || categoriesData.length === 0 || !ids || ids.length === 0) {
            return [];
        }

        const findCategoriesRecursively = (categories: any[]): any[] => {
            let result: any[] = [];
            for (const category of categories) {
                if (ids.includes(category.id?.toString()) || ids.includes(category.id)) {
                    result.push(category);
                }
                if (category.children && category.children.length > 0) {
                    result = result.concat(findCategoriesRecursively(category.children));
                }
            }
            return result;
        };

        return findCategoriesRecursively(categoriesData);
    };


    return (
        <div className="homepage w-full flex flex-col items-center">
            <CustomMarquee
                text={'Lecții video clare și bine structurate, fără detalii irelevante'}
                speed={50}
                gradient={false}
                className={'text-4xl mt-1 bg-stone-700/80'}
            />
            <VideoLinks/>

            <CustomMarquee
                text={'Explorează teste și lecții organizate pe module'}
                speed={50}
                gradient={false}
                className={'text-4xl mt-1 bg-stone-700/80'}
            />
            <div className="flex flex-col w-full">
                <SearchLinksCards/>
            </div>
            <FeaturesSection/>
            <DevelopmentNotice/>
            <AdvantagesSection/>
            <div className="mb-2 w-full">
                <CustomMarquee
                    text={homepageData?.sections?.section1?.text}
                    speed={50}
                    gradient={false}
                    className={'text-4xl mt-1 bg-stone-700/80'}
                />
                <CategoryCards categories={getSelectedCategories(homepageData?.sections?.section1?.categories)}/>
            </div>
        </div>
    );
};
