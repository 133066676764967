import React, {useState, useEffect} from 'react';
import {Lock, Unlock} from 'lucide-react';

declare global {
    interface Window {
        BASE_URL: string;
    }
}

export const AnimatedLoginPrompt = () => {
    const [isLocked, setIsLocked] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setIsLocked(prev => !prev);
        }, 2000);
        return () => clearInterval(interval);
    }, []);

    const loginUrl = `${window.BASE_URL}customer/account/login`;
    const createAccountUrl = `${window.BASE_URL}customer/account/create`;
    const testHistory = `${window.BASE_URL}customer_account/tests/history/`;

    return (
        <div className="w-full flex flex-col items-center justify-center p-4 md:p-8">
            <div className="bg-card-bg shadow-round-shadow flex flex-col items-center justify-center p-8 w-full md:w-1/2 rounded-xl">
                <div className="flex items-center justify-center mb-6">
                    {isLocked ? (
                        <Lock className="w-16 h-16 text-yellow-500 animate-bounce"/>
                    ) : (
                        <Unlock className="w-16 h-16 text-olive-color animate-pulse"/>
                    )}
                </div>

                <h2 className="text-4xl font-bold font-font-p text-gray-800 mb-4">
                    🎮 Intră în cont pentru a continua
                </h2>

                <p className="text-2xl text-center font-font-s text-gray-700 mb-6">
                    Pentru a accesa lecțiile și testele, ai nevoie de un cont! 🚀
                </p>

                <p className="text-2xl font-semibold text-center font-font-s text-text-color mb-6">
                    Cu contul tău: <br/>
                    ✨ Salvezi rezultatele testelor <br/>
                    📊 Vezi progresul <br/>
                    🏆 Poți activa abonamente pentru materia dorită
                </p>

                <div className="flex flex-col md:flex-row gap-4">
                    <a href={loginUrl} className="no-underline hover:no-underline">
                        <button className="text-2xl font-font-p bg-olive-color hover:bg-olive-color text-white font-bold font-font-p py-2 px-6 hover:text-white
                            flex items-center transition duration-300 ease-in-out transform hover:scale-105 rounded-full">
                            Autentifică-te
                        </button>
                    </a>

                    <a href={createAccountUrl} className="no-underline hover:no-underline">
                        <button className="text-2xl font-font-p bg-white border-2 border-olive-color text-olive-color hover:text-white
                            hover:bg-olive-color font-bold font-font-p py-2 px-6 flex items-center
                            focus:bg-olive-color active:olive-color focus:text-white active:text-white
                            transition duration-300 ease-in-out transform hover:scale-105 rounded-full">
                            Creează cont
                        </button>
                    </a>
                </div>

                <p className="mt-6 text-2xl text-center font-font-s text-gray-600">
                    Cu un cont vei putea vedea
                    <a href={testHistory} className="text-peru-color hover:text-olive-color mx-1 no-underline hover:underline font-font-s font-bold">
                        evoluția ta și acces la istoricul testelor salvate! 🌟
                    </a>
                </p>
            </div>
        </div>
    );
};
