import React from 'react';

export const NationalEvaluationTestAdminView = ({ testData }) => {
    // Verificăm dacă avem date valide
    if (!testData) return <div>Nu există date disponibile pentru acest test.</div>;

    // Extragem detaliile de scor
    const scoreDetails = testData.score_details || {};

    return (
        <div className="national-evaluation bg-white w-full flex flex-col gap-3 p-4 border shadow-sm">
            <h2 className="uppercase font-semibold text-gray-800 text-2xl">
                Evaluare Națională - Rezultate
            </h2>

            {/* Scor total - afișat sus pentru a fi ușor de văzut în admin */}
            <section className="score-summary bg-gray-50 p-4 border rounded mb-6">
                <h3 className="text-xl font-bold text-gray-700 mb-3">Punctaj Total</h3>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                    {scoreDetails.subiect1A && (
                        <div className="p-3 border rounded bg-white">
                            <p className="font-semibold">Subiectul 1A</p>
                            <p>{scoreDetails.subiect1A.score}/{scoreDetails.subiect1A.max_score} puncte</p>
                        </div>
                    )}
                    {scoreDetails.subiect1B && (
                        <div className="p-3 border rounded bg-white">
                            <p className="font-semibold">Subiectul 1B</p>
                            <p>{scoreDetails.subiect1B.score}/{scoreDetails.subiect1B.max_score} puncte</p>
                        </div>
                    )}
                    {scoreDetails.subiect2 && (
                        <div className="p-3 border rounded bg-white">
                            <p className="font-semibold">Subiectul 2</p>
                            <p>{scoreDetails.subiect2.score}/{scoreDetails.subiect2.max_score} puncte</p>
                        </div>
                    )}
                    {scoreDetails.total && (
                        <div className="p-3 border bg-yellow-50 rounded">
                            <p className="font-semibold">Total</p>
                            <p className="text-lg font-bold">{scoreDetails.total.score}/{scoreDetails.total.max_score} puncte</p>
                        </div>
                    )}
                </div>
            </section>

            {/* Textele inițiale */}
            {testData.subiectI?.initialTexts && (
                <section className="initial-texts mb-8">
                    <h2 className="text-4xl font-semibold mb-4 text-wood-color">
                        SUBIECT I
                    </h2>
                    {testData.subiectI.initialTexts.map((text) => (
                        <div key={text.id} className="flex flex-col gap-3 mb-3 border p-3">
                            <p className="font-semibold text-gray-700">
                                Textul {text.id}
                            </p>
                            <div
                                dangerouslySetInnerHTML={{ __html: text.text }}
                                className="prose prose-sm max-w-none bg-white p-3 border text-2xl"
                            />
                        </div>
                    ))}
                </section>
            )}

            {/* Partea A */}
            {testData.subiectI?.partA && (
                <section className="partA mb-8">
                    <h2 className="text-3xl font-semibold mb-4 text-wood-color">Partea A</h2>

                    {/* MultipleAnswers */}
                    {testData.subiectI.partA.multipleAnswers?.map((item) => (
                        <div key={item.id} className="mb-6">
                            <div className="flex flex-col gap-3 border p-3 mb-4">
                                <h3 className="text-2xl font-semibold mb-4 text-wood-color">1</h3>
                                <div className="bg-white p-3 border">
                                    <div
                                        dangerouslySetInnerHTML={{ __html: item.text }}
                                        className="prose prose-sm max-w-none text-2xl"
                                    />
                                </div>
                                <div className="mt-4 bg-gray-50 p-4">
                                    <h3 className="font-semibold text-gray-700">Răspunsurile elevului:</h3>
                                    {item.userAnswers.map((answer, idx) => {
                                        if (!answer.userAnswer) return null;
                                        return (
                                            <div key={idx} className="flex items-start gap-2 mt-2 border-t pt-2">
                                                <span className="font-medium">{idx + 1}:</span>
                                                <span className={`${answer.correctAnswer === answer.userAnswer ? 'text-green-600' : 'text-red-600'}`}>
                                                    {answer.userAnswer}
                                                </span>
                                                {answer.correctAnswer && (
                                                    <div className="ml-4">
                                                        <span className="font-medium">Răspuns corect:</span>
                                                        <span className="text-green-600 ml-2">{answer.correctAnswer}</span>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    ))}

                    {/* Grid Questions */}
                    {testData.subiectI.partA.gridQuestions?.map((question, index) => (
                        <div key={question.id} className="flex flex-col gap-3 border p-3 mb-6">
                            <h3 className="text-2xl font-semibold mb-4 text-wood-color">{index + 2}</h3>
                            <div className="bg-white p-3 border">
                                <div
                                    dangerouslySetInnerHTML={{ __html: question.questionText }}
                                    className="prose prose-sm max-w-none text-2xl"
                                />
                            </div>
                            <div className="mt-4 bg-gray-50 p-4">
                                <div className="flex items-start gap-2 border-b pb-2 mb-2">
                                    <span className="font-semibold text-gray-700">Răspunsul elevului:</span>
                                    <span className={`${question.userAnswer === question.correctAnswer ? 'text-green-600' : 'text-red-600'}`}>
                                        {question.answers[`answer${question.userAnswer}`] || 'Niciun răspuns selectat'}
                                    </span>
                                </div>
                                <div className="flex items-start gap-2">
                                    <span className="font-semibold text-gray-700">Răspuns corect:</span>
                                    <span className="text-green-600">{question.answers[`answer${question.correctAnswer}`]}</span>
                                </div>
                            </div>
                        </div>
                    ))}

                    {/* True/False Questions */}
                    {testData.subiectI.partA.trueFalseQuestions && testData.subiectI.partA.trueFalseQuestions.length > 0 && (
                        <div className="flex flex-col gap-3 border p-3 mb-6">
                            <h3 className="text-2xl font-semibold mb-4 text-wood-color">5</h3>
                            {testData.subiectI.partA.trueFalseQuestions.map((question) => (
                                <div key={question.id} className="flex flex-col gap-3 mb-4 border-b pb-4">
                                    <div className="bg-white p-3 border">
                                        <div
                                            dangerouslySetInnerHTML={{ __html: question.text }}
                                            className="prose prose-sm max-w-none text-2xl"
                                        />
                                    </div>
                                    <div className="mt-2 bg-gray-50 p-3">
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                                            <div>
                                                <span className="font-semibold text-gray-700">Răspunsul elevului:</span>
                                                <span className={`ml-2 ${question.userAnswer === question.isCorrect ? 'text-green-600' : 'text-red-600'}`}>
                                                    {question.userAnswer ? 'Adevărat' : 'Fals'}
                                                </span>
                                            </div>
                                            <div>
                                                <span className="font-semibold text-gray-700">Răspuns corect:</span>
                                                <span className="ml-2 text-green-600">{question.isCorrect ? 'Adevărat' : 'Fals'}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    {/* Requirements */}
                    {testData.subiectI.partA.requirements?.map((requirement, index) => (
                        <div key={requirement.id} className="border p-3 mb-6">
                            <h3 className="text-2xl font-semibold mb-4 text-wood-color">
                                {index + 6}
                            </h3>
                            <div className="bg-white p-3 border mb-3">
                                <div
                                    dangerouslySetInnerHTML={{ __html: requirement.text }}
                                    className="prose prose-sm max-w-none text-2xl"
                                />
                            </div>
                            <div className="bg-gray-50 p-3">
                                <h3 className="font-semibold text-gray-700">Răspunsul elevului:</h3>
                                <p className="mt-2 p-2 bg-white border text-2xl">{requirement.userAnswer || 'Niciun răspuns oferit'}</p>
                            </div>
                        </div>
                    ))}
                </section>
            )}

            {/* Partea B */}
            {testData.subiectI?.partB && (
                <section className="partB mb-8">
                    <h2 className="text-3xl font-semibold mb-4 text-wood-color">Partea B</h2>

                    {/* Grid Questions */}
                    {testData.subiectI.partB.gridQuestions?.map((question, index) => (
                        <div key={question.id} className="flex flex-col gap-3 border p-3 mb-6">
                            <h3 className="text-2xl font-semibold mb-4 text-wood-color">
                                {index + 1}
                            </h3>
                            <div className="bg-white p-3 border">
                                <div
                                    dangerouslySetInnerHTML={{ __html: question.questionText }}
                                    className="prose prose-sm max-w-none text-2xl"
                                />
                            </div>
                            <div className="mt-4 bg-gray-50 p-4">
                                <div className="flex items-start gap-2 border-b pb-2 mb-2">
                                    <span className="font-semibold text-gray-700">Răspunsul elevului:</span>
                                    <span className={`${question.userAnswer === question.correctAnswer ? 'text-green-600' : 'text-red-600'}`}>
                                        {question.answers[`answer${question.userAnswer}`] || 'Niciun răspuns selectat'}
                                    </span>
                                </div>
                                <div className="flex items-start gap-2">
                                    <span className="font-semibold text-gray-700">Răspuns corect:</span>
                                    <span className="text-green-600">{question.answers[`answer${question.correctAnswer}`]}</span>
                                </div>
                            </div>
                        </div>
                    ))}

                    {/* Multiple Answers */}
                    {testData.subiectI.partB.multipleAnswers?.map((item) => (
                        <div key={item.id} className="mb-6">
                            <div className="flex flex-col gap-3 border p-3 mb-4">
                                <h3 className="text-2xl font-semibold mb-4 text-wood-color">5</h3>
                                <div className="bg-white p-3 border">
                                    <div
                                        dangerouslySetInnerHTML={{ __html: item.text }}
                                        className="prose prose-sm max-w-none text-2xl"
                                    />
                                </div>
                                <div className="mt-4 bg-gray-50 p-4">
                                    <h3 className="font-semibold text-gray-700">Răspunsurile elevului:</h3>
                                    {item.userAnswers.map((answer, idx) => {
                                        if (!answer.userAnswer) return null;
                                        return (
                                            <div key={idx} className="flex items-start gap-2 mt-2 border-t pt-2">
                                                <span className="font-medium">{idx + 1}:</span>
                                                <span className={`${answer.correctAnswer === answer.userAnswer ? 'text-green-600' : 'text-red-600'}`}>
                                                    {answer.userAnswer}
                                                </span>
                                                {answer.correctAnswer && (
                                                    <div className="ml-4">
                                                        <span className="font-medium">Răspuns corect:</span>
                                                        <span className="text-green-600 ml-2">{answer.correctAnswer}</span>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    ))}

                    {/* Requirements */}
                    {testData.subiectI.partB.requirements?.map((requirement, index) => (
                        <div key={requirement.id} className="border p-3 mb-6">
                            <p className="text-2xl font-semibold mb-4 text-wood-color">
                                {index + 6}
                            </p>
                            <div className="bg-white p-3 border mb-3">
                                <div
                                    dangerouslySetInnerHTML={{ __html: requirement.text }}
                                    className="prose prose-sm max-w-none text-2xl"
                                />
                            </div>
                            <div className="bg-gray-50 p-3">
                                <h3 className="font-semibold text-gray-700">Răspunsul elevului:</h3>
                                <p className="mt-2 p-2 bg-white border text-2xl">{requirement.userAnswer || 'Niciun răspuns oferit'}</p>
                            </div>
                        </div>
                    ))}
                </section>
            )}

            {/* Subiectul II */}
            {testData.subiectII?.requirements && (
                <section className="subiect2 mb-8">
                    <h2 className="text-4xl font-semibold mb-4 text-wood-color">
                        SUBIECT II
                    </h2>
                    {testData.subiectII.requirements.map((requirement) => (
                        <div key={requirement.id} className="border p-3 mb-6">
                            <div className="bg-white p-3 border mb-3">
                                <div
                                    dangerouslySetInnerHTML={{ __html: requirement.text }}
                                    className="prose prose-sm max-w-none text-2xl"
                                />
                            </div>
                            <div className="bg-gray-50 p-3">
                                <h3 className="font-semibold text-gray-700">Răspunsul elevului:</h3>
                                <p className="mt-2 p-2 bg-white border text-2xl">{requirement.userAnswer || 'Niciun răspuns oferit'}</p>
                            </div>
                        </div>
                    ))}
                </section>
            )}
        </div>
    );
};
