import React from "react";
import {QueryClientProvider} from "@tanstack/react-query";
import {CheckoutProvider} from "../../../Checkout";
import {
    ProductAttributeProvider,
    RoGridTestProductProvider,
    RoNationalEvaluationProvider,
    RoTestDataProductProvider
} from "../../state";
import {client} from "../../../Infrastructure/models/client";
import {ProductInfo} from "./ProductInfo";
import {ProductDetails} from "./ProductDetails";
import {PageLeftSide} from "./Sidebar/PageLeftSide";
import {PageRightSide} from "./Sidebar/PageRightSide";

interface Product {
    productSku: string;
    product_id: number;
    productFlags?: {
        isNew: boolean;
        isFree: boolean;
    };
}

const queryClient = client();

export const ProductView: React.FC<Product> = ({productSku, product_id, productFlags}) => {

    return (
        <QueryClientProvider client={queryClient}>
            <CheckoutProvider>
                <ProductAttributeProvider productSku={productSku} productFlags={productFlags}>
                    <RoTestDataProductProvider product_id={product_id}>
                        <RoGridTestProductProvider product_id={product_id}>
                            <RoNationalEvaluationProvider product_id={product_id}>
                                <PageLeftSide/>
                                <div
                                    className={'w-full md:max-w-4/6 w-[1440px] flex flex-col justify-center items-center relative '}
                                >
                                    <ProductInfo productSku={productSku}/>
                                    <ProductDetails productSku={productSku}/>

                                </div>
                                <PageRightSide/>
                            </RoNationalEvaluationProvider>
                        </RoGridTestProductProvider>
                    </RoTestDataProductProvider>
                </ProductAttributeProvider>
            </CheckoutProvider>
        </QueryClientProvider>
    );
}
