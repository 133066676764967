import React from 'react';

export const DevelopmentNotice = () => {
    return (
        <div className="w-full flex flex-col items-center bg-yellow-700/80">
            <div className="w-full flex flex-col items-center p-10 text-white max-w-[1440px]">
                <h2 className="text-6xl font-font-p font-bold text-center mb-5 ">Platforma este în dezvoltare</h2>
                <p className="text-3xl md:text-4xl font-font-s text-center mb-6 max-w-[1000px] leading-loose">
                    Lucrăm continuu la îmbunătățirea conținutului și funcționalităților platformei. Pe măsură ce
                    avansăm,
                    este posibil ca unele secțiuni să fie temporar indisponibile sau în proces de optimizare.
                </p>
                <p className="text-3xl md:text-4xl font-font-s text-center max-w-[1000px] leading-loose">
                    Apelăm la răbdarea și înțelegerea voastră și vă asigurăm că fiecare actualizare este gândită pentru
                    a vă oferi o experiență educațională cât mai utilă și accesibilă.
                </p>
            </div>
        </div>
    );
};
