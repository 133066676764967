import React from 'react';
import {TestFeedback} from './TestFeedback';

export const TestResult = ({score, saveMessage}) => {
    const testHistory = `${window.BASE_URL}customer_account/tests/history/`;

    const scoreDetails = score.test_score;

    return (
        <div className="test-result bg-card-bg flex flex-col items-center gap-3 p-3">
            <h2 className={''}>Test Completat</h2>
            <p className="text-xl font-font-s text-center text-text-color mb-3">
                {'Poți să vezi răspunsurile și rezultatele în'} {' '}
                <a href={testHistory}
                   className="text-xl font-semibold font-font-s text-center text-peru-color mb-3 no-underline hover:underline hover:text-text-color">
                    {'istoricul testelor'} {' '}
                </a>
                {'din contul tău de client.'}
            </p>
            <div className="score-details flex flex-col gap-2 text-center">
                <p className="text-2xl font-font-s">
                    Punctaj: {scoreDetails.total_points} din {scoreDetails.max_possible_points} puncte
                </p>
                <p className="text-xl font-font-s">
                    Răspunsuri corecte: {scoreDetails.correct_answers} din {scoreDetails.total_questions}
                </p>
            </div>
            <TestFeedback score={scoreDetails.score_percentage}/>
            {saveMessage && (
                <p className="text-olive-color font-font-s text-2xl mt-4">
                    {saveMessage}
                </p>
            )}

            {/* Buton pentru a merge la istoricul testelor */}
            <a href={testHistory}
               className="font-font-s text-2xl mt-4 px-6 py-2 bg-peru-color text-white rounded-lg
                            hover:bg-peru-color-dark hover:text-white transition-colors">
                Vezi istoricul testelor
            </a>
        </div>
    );
};
