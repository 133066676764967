import React, {useCallback, useEffect, useState} from 'react';
import {NationalEvaluationProvider, useNationalEvaluation} from "../../state";
import {client} from "../../../Infrastructure/models/client";
import {QueryClientProvider} from "@tanstack/react-query";
import {InitialTextsSection} from "./RoTests/assets/InitialTextsSection";
import type {NationalEvaluationData} from '../../state/content/NationalEvaluationProvider';
import {GridSection} from "./RoTests/assets/GridSection";
import {RequirementsSection} from "./RoTests/assets/RequirementsSection";
import {TrueFalseSection} from "./RoTests/assets/TrueFalseSection";
import {MultiAnswerSection} from "./RoTests/assets/MultiAnswerSection";

const queryClient = client();

const AdminNationalEvaluationForm: React.FC<{ product_id: number; productSku: string }> = ({
                                                                                               product_id,
                                                                                               productSku
                                                                                           }) => {
    const {
        evaluationData,
        loading,
        saveInitialTexts,
        savePartAMultipleAnswers,
        savePartBMultipleAnswers,
        savePartAGrid,
        savePartBGrid,
        savePartARequirements,
        savePartBRequirements,
        saveSub2Requirements,
        savePartATrueFalse
    } = useNationalEvaluation();
    const [localTest, setLocalTest] = useState<NationalEvaluationData | null>(null);

    useEffect(() => {
        if (evaluationData && evaluationData.product_id) {
            setLocalTest(evaluationData);
        }
    }, [evaluationData]);

    // Existing Initial Texts functions
    const updateInitialText = (id: number, content: string) => {
        if (!localTest) return;
        setLocalTest({
            ...localTest,
            subiectI: {
                ...localTest.subiectI,
                initialTexts: localTest.subiectI.initialTexts.map(item =>
                    item.id === id ? {...item, text: content} : item
                )
            }
        });
    };

    const updateMultiAnswerText = (part: 'partA' | 'partB', content: string) => {
        if (!localTest) return;
        setLocalTest({
            ...localTest,
            subiectI: {
                ...localTest.subiectI,
                [part]: {
                    ...localTest.subiectI[part],
                    multipleAnswers: [
                        {
                            ...localTest.subiectI[part].multipleAnswers[0],
                            text: content
                        }
                    ]
                }
            }
        });
    };

    const updateMultiAnswerTitle = (part: 'partA' | 'partB', title: string) => {
        if (!localTest) return;
        setLocalTest({
            ...localTest,
            subiectI: {
                ...localTest.subiectI,
                [part]: {
                    ...localTest.subiectI[part],
                    multipleAnswers: [
                        {
                            ...localTest.subiectI[part].multipleAnswers[0],
                            title: title
                        }
                    ]
                }
            }
        });
    };

    const updateMultiAnswerAnswer = (part: 'partA' | 'partB', answerId: number, text: string) => {
        if (!localTest) return;
        setLocalTest({
            ...localTest,
            subiectI: {
                ...localTest.subiectI,
                [part]: {
                    ...localTest.subiectI[part],
                    multipleAnswers: [
                        {
                            ...localTest.subiectI[part].multipleAnswers[0],
                            answers: localTest.subiectI[part].multipleAnswers[0].answers.map(answer =>
                                answer.id === answerId ? {...answer, text: text} : answer
                            )
                        }
                    ]
                }
            }
        });
    };

    const updateGridText = (part: string, subjectId: number, content: string) => {
        if (!localTest) return;
        setLocalTest({
            ...localTest,
            subiectI: {
                ...localTest.subiectI,
                [part]: {
                    ...localTest.subiectI[part],
                    gridQuestions: localTest.subiectI[part].gridQuestions.map(subject =>
                        subject.id === subjectId ? {...subject, questionText: content} : subject
                    )
                }
            }
        });
    };

    const updateGridSubject = useCallback((part: string, subjectId: number, field: string, value: any) => {
        setLocalTest(prevTest => {
            if (!prevTest) return prevTest;

            const processedValue = field === 'correctAnswer' ? Number(value) : value;
            return {
                ...prevTest,
                subiectI: {
                    ...prevTest.subiectI,
                    [part]: {
                        ...prevTest.subiectI[part],
                        gridQuestions: prevTest.subiectI[part].gridQuestions.map(subject =>
                            subject.id === subjectId ? {...subject, [field]: processedValue} : subject
                        )
                    }
                }
            };
        });
    }, []);

    const updateRequirement = (part: string, id: number, content: string) => {
        if (!localTest) return;

        let updatedTest = {...localTest};

        switch (part) {
            case 'partA':
                updatedTest.subiectI.partA.requirements = updatedTest.subiectI.partA.requirements.map(
                    req => req.id === id ? {...req, text: content} : req
                );
                break;
            case 'partB':
                updatedTest.subiectI.partB.requirements = updatedTest.subiectI.partB.requirements.map(
                    req => req.id === id ? {...req, text: content} : req
                );
                break;
            case 'sub2':
                updatedTest.subiectII.requirements = updatedTest.subiectII.requirements.map(
                    req => req.id === id ? {...req, text: content} : req
                );
                break;
        }

        setLocalTest(updatedTest);
    };

    const updateTrueFalse = (id: number, field: string, value: any) => {
        if (!localTest) return;
        setLocalTest({
            ...localTest,
            subiectI: {
                ...localTest.subiectI,
                partA: {
                    ...localTest.subiectI.partA,
                    trueFalseQuestions: localTest.subiectI.partA.trueFalseQuestions.map(q =>
                        q.id === id ? {...q, [field]: value} : q
                    )
                }
            }
        });
    };

    const handleSave = async () => {
        if (!localTest) return;
        try {
            // Save Initial Texts
            const initialTextsPayload = localTest.subiectI.initialTexts.map(block => ({
                id: block.id,
                text: block.text,
            }));
            await saveInitialTexts(initialTextsPayload);

            // Save Grid Part A
            if (localTest.subiectI?.partA?.gridQuestions) {
                const gridQuestionsA = localTest.subiectI.partA.gridQuestions.map(question => ({
                    id: question.id,
                    questionText: question.questionText || '',
                    answer1: question.answer1,
                    answer2: question.answer2,
                    answer3: question.answer3,
                    answer4: question.answer4,
                    correctAnswer: Number(question.correctAnswer)
                }));
                await savePartAGrid(gridQuestionsA);
            }

            // Save Grid Part B
            if (localTest.subiectI?.partB?.gridQuestions) {
                const gridQuestionsB = localTest.subiectI.partB.gridQuestions.map(question => ({
                    id: question.id,
                    questionText: question.questionText || '',
                    answer1: question.answer1,
                    answer2: question.answer2,
                    answer3: question.answer3,
                    answer4: question.answer4,
                    correctAnswer: Number(question.correctAnswer)
                }));
                await savePartBGrid(gridQuestionsB);
            }

            if (localTest.subiectI?.partA?.requirements) {
                await savePartARequirements(localTest.subiectI.partA.requirements);
            }

            if (localTest.subiectI?.partB?.requirements) {
                await savePartBRequirements(localTest.subiectI.partB.requirements);
            }

            if (localTest.subiectII?.requirements) {
                await saveSub2Requirements(localTest.subiectII.requirements);
            }

            if (localTest.subiectI?.partA?.trueFalseQuestions) {
                await savePartATrueFalse(localTest.subiectI.partA.trueFalseQuestions);
            }

            if (localTest.subiectI?.partA?.multipleAnswers?.[0]) {  // Verificăm că există primul element
                await savePartAMultipleAnswers({
                    id: localTest.subiectI.partA.multipleAnswers[0].id,
                    text: localTest.subiectI.partA.multipleAnswers[0].text,
                    title: localTest.subiectI.partA.multipleAnswers[0].title,
                    answers: localTest.subiectI.partA.multipleAnswers[0].answers
                });
            }

            if (localTest.subiectI?.partB?.multipleAnswers?.[0]) {  // Verificăm că există primul element
                await savePartBMultipleAnswers({
                    id: localTest.subiectI.partB.multipleAnswers[0].id,
                    text: localTest.subiectI.partB.multipleAnswers[0].text,
                    title: localTest.subiectI.partB.multipleAnswers[0].title,
                    answers: localTest.subiectI.partB.multipleAnswers[0].answers
                });
            }
            alert('Toate secțiunile au fost salvate cu succes');
        } catch (error) {
            console.error('Eroare la salvare:', error);
            alert('A apărut o eroare la salvare');
        }
    };

    if (loading || !localTest) return <div>Loading...</div>;

    return (
        <div className="p-4">
            <div className="page-main-actions">
                <div className="page-actions">
                    <button
                        className="action-secondary back"
                        type="button"
                        onClick={() => window.history.back()}
                    >
                        Back
                    </button>
                    <button onClick={handleSave} className="action-primary save" type="submit">
                        Save Test
                    </button>
                </div>
            </div>
            <div className="space-y-8">
                <h2 className="text-4xl font-semibold mb-4 text-wood-color">
                    SUBIECT I
                </h2>
                <h3 className="text-2xl font-semibold mb-4">
                    Citește fiecare dintre textele de mai jos pentru a putea răspunde la cerințele formulate.
                </h3>

                {/* Initial Texts Section */}
                <div className="flex flex-col gap-4 mb-8">
                    <InitialTextsSection
                        initialTexts={localTest.subiectI.initialTexts}
                        updateInitialText={updateInitialText}
                    />
                </div>

                {/* PART A*/}
                <h2 className="text-3xl font-semibold mb-4 text-wood-color">Partea A</h2>

                <div className="mb-8">
                    <h3 className="text-2xl font-semibold mb-4 text-wood-color">1</h3>
                    <MultiAnswerSection
                        question={localTest.subiectI.partA.multipleAnswers[0]}
                        onUpdateText={(content) => updateMultiAnswerText('partA', content)}
                        onUpdateTitle={(title) => updateMultiAnswerTitle('partA', title)}
                        onUpdateAnswer={(answerId, text) => updateMultiAnswerAnswer('partA', answerId, text)}
                    />
                </div>

                {/* Grid Part A */}
                <div className="mb-8">
                    {localTest.subiectI.partA.gridQuestions.map((subject, index) => (
                        <>
                            <h3 className="text-2xl font-semibold mb-4 text-wood-color">{index + 2}</h3>
                            <GridSection
                                key={subject.id}
                                subject={subject}
                                part="partA"
                                updateGridText={updateGridText}
                                updateGridSubject={updateGridSubject}
                            />
                        </>
                    ))}
                </div>

                {/* True/False Section Part A */}
                <div className="mb-8">
                    <h3 className="text-2xl font-semibold mb-4 text-wood-color">5</h3>
                    <p className='text-xl font-font-s text-text-color'>
                        Selecteaza în dreptul fiecărui enunț adevărat sau fals, bazându-te pe informațiile din cele două
                        texte.
                    </p>

                    <TrueFalseSection
                        trueFalseQuestions={localTest.subiectI.partA.trueFalseQuestions}
                        updateTrueFalseQuestion={updateTrueFalse}
                    />
                </div>

                {/* Requirements Part A */}
                <div className="mb-8">
                    <h3 className="text-2xl font-semibold mb-4 text-wood-color">

                    </h3>
                    <RequirementsSection
                        requirements={localTest.subiectI.partA.requirements}
                        updateRequirement={(id, content) => updateRequirement('partA', id, content)}
                        startIndex={5}
                    />
                </div>

                {/* Part B */}
                <h2 className="text-3xl font-semibold mb-4 text-wood-color">Partea B</h2>

                <div className="mb-8">
                    {localTest.subiectI.partB.gridQuestions.map((subject, index) => (
                        <>
                            <h3 className="text-2xl font-semibold mb-4 text-wood-color">{index + 1}</h3>
                            <GridSection
                                key={subject.id}
                                subject={subject}
                                part="partB"
                                updateGridText={updateGridText}
                                updateGridSubject={updateGridSubject}
                            />
                        </>

                    ))}
                </div>

                <div className="mb-8">
                    <h3 className="text-2xl font-semibold mb-4 text-wood-color">5</h3>
                    <MultiAnswerSection
                        question={localTest.subiectI.partB.multipleAnswers[0]}
                        onUpdateText={(content) => updateMultiAnswerText('partB', content)}
                        onUpdateTitle={(title) => updateMultiAnswerTitle('partB', title)}
                        onUpdateAnswer={(answerId, text) => updateMultiAnswerAnswer('partB', answerId, text)}
                    />
                </div>

                {/* Requirements Part B */}
                <div className="mb-8">
                    <h3 className="text-xl font-semibold mb-4">Partea B - Cerințe</h3>
                    <RequirementsSection
                        requirements={localTest.subiectI.partB.requirements}
                        updateRequirement={(id, content) => updateRequirement('partB', id, content)}
                        startIndex={5}
                    />
                </div>


                {/* Requirements Subject II */}
                <div className="mb-8">
                    <h2 className="text-4xl font-semibold mb-4 text-wood-color">
                        SUBIECT II
                    </h2>
                    <RequirementsSection
                        requirements={localTest.subiectII.requirements}
                        updateRequirement={(id, content) => updateRequirement('sub2', id, content)}
                    />
                </div>
            </div>
        </div>
    );
};

export const AdminNationalEvaluationFormWrapper: React.FC<{ product_id: number; productSku: string }> = (props) => (
    <QueryClientProvider client={queryClient}>
        <NationalEvaluationProvider product_id={props.product_id} productSku={props.productSku}>
            <AdminNationalEvaluationForm {...props} />
        </NationalEvaluationProvider>
    </QueryClientProvider>
);
