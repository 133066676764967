import React from 'react';
import { QuillEditor } from "./QuillEditor";

export const TrueFalseSection = ({ trueFalseQuestions, updateTrueFalseQuestion }) => {
    const firstThreeAnswers = trueFalseQuestions.slice(0, 3);
    const lastThreeAnswers = trueFalseQuestions.slice(3, 6);

    return (
        <div className="flex flex-row gap-4">
            <div className='flex flex-col gap-4'>
                <h3 className="text-lg font-semibold mb-6">Textul 1</h3>
                {firstThreeAnswers.map((question, index) => (
                    <div key={question.id} className="space-y-4 border-b pb-6 last:border-0">
                        <div className="flex flex-col gap-4">
                            <div className="flex-grow">
                                <QuillEditor
                                    value={question.text}
                                    onEditorChange={(content) =>
                                        updateTrueFalseQuestion(question.id, 'text', content)
                                    }
                                    placeholder={`Introduceți afirmația ${index + 1}...`}
                                    className="bg-white h-32 mb-20"
                                />
                            </div>
                            <div className="flex flex-row items-start pt-2 space-x-4">
                                <label className="flex items-center gap-2">
                                    <input
                                        type="radio"
                                        name={`tf-${question.id}`}
                                        checked={question.isCorrect === true}
                                        onChange={() =>
                                            updateTrueFalseQuestion(question.id, 'isCorrect', true)
                                        }
                                        className="w-4 h-4"
                                    />
                                    <span>Adevărat</span>
                                </label>
                                <label className="flex items-center gap-2">
                                    <input
                                        type="radio"
                                        name={`tf-${question.id}`}
                                        checked={question.isCorrect === false}
                                        onChange={() =>
                                            updateTrueFalseQuestion(question.id, 'isCorrect', false)
                                        }
                                        className="w-4 h-4"
                                    />
                                    <span>Fals</span>
                                </label>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className='flex flex-col gap-4'>
                <h3 className="text-lg font-semibold mb-6">Textul 2</h3>
                {lastThreeAnswers.map((question, index) => (
                    <div key={question.id} className="space-y-4 border-b pb-6 last:border-0">
                        <div className="flex flex-col gap-4">
                            <div className="flex-grow">
                                <QuillEditor
                                    value={question.text}
                                    onEditorChange={(content) =>
                                        updateTrueFalseQuestion(question.id, 'text', content)
                                    }
                                    placeholder={`Introduceți afirmația ${index + 1}...`}
                                    className="bg-white h-32 mb-20"
                                />
                            </div>
                            <div className="flex flex-row items-start pt-2 space-x-4">
                                <label className="flex items-center gap-2">
                                    <input
                                        type="radio"
                                        name={`tf-${question.id}`}
                                        checked={question.isCorrect === true}
                                        onChange={() =>
                                            updateTrueFalseQuestion(question.id, 'isCorrect', true)
                                        }
                                        className="w-4 h-4"
                                    />
                                    <span>Adevărat</span>
                                </label>
                                <label className="flex items-center gap-2">
                                    <input
                                        type="radio"
                                        name={`tf-${question.id}`}
                                        checked={question.isCorrect === false}
                                        onChange={() =>
                                            updateTrueFalseQuestion(question.id, 'isCorrect', false)
                                        }
                                        className="w-4 h-4"
                                    />
                                    <span>Fals</span>
                                </label>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
