import React from 'react';
import { QuillEditor } from "./QuillEditor";

export const InitialTextsSection = ({ initialTexts, updateInitialText }) => {
    return (
        <div className="flex flex-col gap-4 ">
            {initialTexts.map(block => (
                <div key={block.id} className="flex flex-col gap-4 ">
                    <div className="flex flex-col gap-4 mb-20">
                        <label className="font-medium">
                            Text {block.id}
                        </label>
                        <QuillEditor
                            value={block.text}
                            onEditorChange={(content) => updateInitialText(block.id, content)}
                            placeholder={`Introduceți textul ${block.id}...`}
                            className="bg-white h-48"
                        />
                    </div>
                </div>
            ))}
        </div>
    );
};
