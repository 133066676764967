import React from 'react';
import {useWindowSize} from '../../../../general';
import {HomepageProvider} from '../../state';
import {QueryClientProvider} from "@tanstack/react-query";
import {client} from "../../../Infrastructure/models/client";
import {HomeMainContent} from './HomeCategoryContent';
import {PageLeftSide} from "./sidebar/PageLeftSide";
import {PageRightSide} from "./sidebar/PageRightSide";

const queryClient = client();

const cmsBlockIdentifiers = {
    block1: "homepage_img_slider",
    block2: "homepage_text_1",
    block3: "homepage_text_2",
    block4: "homepage_text_3"
};

export const Homepage: React.FC = () => {
    const {isMobile, isTablet} = useWindowSize();

    return (
        <QueryClientProvider client={queryClient}>
            <HomepageProvider cmsBlockIdentifiers={cmsBlockIdentifiers}>
                <HomeMainContent/>
            </HomepageProvider>
        </QueryClientProvider>
    );
};
