import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useMutation, useQuery } from "@tanstack/react-query";
import { request } from "../../../Infrastructure";
import {
    getNationalEvaluationData,
    saveCustomerTestData
} from '../../queries';

// Interface pentru date inițiale
interface InitialText {
    id: number;
    text: string;
}

// Interfaces pentru diferite tipuri de întrebări
interface MultipleAnswer {
    id: number;
    text: string;
    title: string;
    answers: Array<{
        id: number;
        text: string;
    }>;
}

interface GridQuestion {
    id: number;
    questionText: string;
    answer1: string;
    answer2: string;
    answer3: string;
    answer4: string;
    correctAnswer: number;
}

interface TrueFalseQuestion {
    id: number;
    text: string;
    isCorrect: boolean;
}

interface Requirement {
    id: number;
    text: string;
}

// Structura pentru părțile testului
interface PartA {
    multipleAnswers: MultipleAnswer[];
    gridQuestions?: GridQuestion[];
    trueFalseQuestions?: TrueFalseQuestion[];
    requirements?: Requirement[];
}

interface PartB {
    gridQuestions?: GridQuestion[];
    multipleAnswers: MultipleAnswer[];
    requirements?: Requirement[];
}

interface SubiectI {
    initialTexts: InitialText[];
    partA: PartA;
    partB: PartB;
}

interface SubiectII {
    requirements?: Requirement[];
}

interface NationalEvaluationContent {
    product_id: number;
    productSku: string;
    title: string;
    status: number;
    createdAt: string;
    updatedAt: string;
    subiectI: SubiectI;
    subiectII: SubiectII;
}

// Structura răspunsului de la API
interface NationalEvaluationData {
    status: string;
    message: string | null;
    nationalEvaluation: NationalEvaluationContent;
}

// Input pentru salvarea datelor testului
interface SaveCustomerTestDataInput {
    product_sku: string;
    test_type: string;
    test_data: string;
    start_time: string;
    end_time: string;
}

// Structura scorului testului
interface TestScore {
    test_score: {
        total_questions: number;
        correct_answers: number;
        incorrect_answers: number;
        points_per_question: number;
        total_points: number;
        max_possible_points: number;
        score_percentage: number;
        details: {
            correct_questions: number[];
            incorrect_questions: number[];
        }
    }
}

// Props pentru contextul provider-ului
interface RoNationalEvaluationContextProps {
    nationalEvaluationData: NationalEvaluationData | null;
    saveTestData: (input: SaveCustomerTestDataInput) => Promise<any>;
    isTestCompleted: boolean;
    setTestCompleted: (completed: boolean) => void;
    testScore: TestScore | null;
    mutationError: string | null;
    clearMutationError: () => void;
    loading: boolean;
    error: Error | unknown;
}

// Crearea contextului
const RoNationalEvaluationContext = createContext<RoNationalEvaluationContextProps | undefined>(undefined);

// Provider component
export const RoNationalEvaluationProvider: React.FC<{ children: ReactNode, product_id: number }> = ({ children, product_id }) => {
    const [nationalEvaluationData, setNationalEvaluationData] = useState<any>(null);
    const [mutationError, setMutationError] = useState<string | null>(null);
    const [isTestCompleted, setIsTestCompleted] = useState(false);
    const [testScore, setTestScore] = useState<TestScore | null>(null);

    // Query to fetch national evaluation data
    const nationalEvaluationQuery = useQuery({
        queryKey: ['nationalEvaluationData', product_id],
        queryFn: async () => {
            const response = await request(getNationalEvaluationData, { product_id });
            return response.data?.getNationalEvaluationData;
        },
        enabled: !!product_id,
        refetchOnWindowFocus: false,
    });

    // Mutation to save test data
    const saveTestDataMutation = useMutation({
        mutationFn: (input: SaveCustomerTestDataInput) =>
            request(saveCustomerTestData, { input }),
    });

    // Save test data function
    const saveTestData = async (input: SaveCustomerTestDataInput) => {
        try {
            if (typeof input.test_data !== 'string') {
                input.test_data = JSON.stringify(input.test_data);
            }
            const result = await saveTestDataMutation.mutateAsync(input);

            if (result.errors && result.errors.length > 0) {
                if (result.errors[0].extensions?.debugMessage?.includes("Tip de test necunoscut")) {
                    setMutationError(null);
                    setIsTestCompleted(true);
                    return { success: true };
                }
                setMutationError(result.errors[0].message);
                return null;
            }

            if (result.data && result.data.saveCustomerTestData) {
                const parsedScore = JSON.parse(result.data.saveCustomerTestData.test_score);

                setMutationError(null);
                setIsTestCompleted(true);
                setTestScore(parsedScore);
                return result.data.saveCustomerTestData;
            }

            setMutationError("Eroare la salvarea datelor testului.");
            return null;
        } catch (error) {
            console.error("Error saving test data:", error);
            setMutationError("A apărut o eroare neașteptată.");
            throw error;
        }
    };

    const setTestCompleted = (completed: boolean) => {
        setIsTestCompleted(completed);
    };

    const clearMutationError = () => setMutationError(null);

    // Update state when data is fetched
    useEffect(() => {
        if (nationalEvaluationQuery.data) {
            setNationalEvaluationData(nationalEvaluationQuery.data);
        }
    }, [nationalEvaluationQuery.data]);

    return (
        <RoNationalEvaluationContext.Provider value={{
            nationalEvaluationData,
            saveTestData,
            setTestCompleted,
            isTestCompleted,
            testScore,
            mutationError,
            clearMutationError,
            loading: nationalEvaluationQuery.isLoading || saveTestDataMutation.isLoading,
            error: nationalEvaluationQuery.error || saveTestDataMutation.error,
        }}>
            {children}
        </RoNationalEvaluationContext.Provider>
    );
};

// Hook to use the context
export const useRoNationalEvaluation = (): RoNationalEvaluationContextProps => {
    const context = useContext(RoNationalEvaluationContext);
    if (!context) {
        throw new Error('useRoNationalEvaluation must be used within a RoNationalEvaluationProvider');
    }
    return context;
};
