import React from 'react';
import {QuillEditor} from "./QuillEditor";

export const MultiAnswerSection = ({question, onUpdateText, onUpdateTitle, onUpdateAnswer}) => {

    if (!question) {
        return null;
    }

    const firstThreeAnswers = question.answers.slice(0, 3);
    const lastThreeAnswers = question.answers.slice(3, 6);

    return (
        <div className="bg-white shadow-sm mb-4 rounded-lg">
            <div className="flex flex-col gap-4 mb-20">
                <QuillEditor
                    value={question.text}
                    onEditorChange={onUpdateText}
                    placeholder="Introduceți textul cerinței..."
                    className="bg-white h-48"
                />
            </div>

            <div className="mt-4 mb-4">
                <input
                    type="text"
                    value={question.title}
                    onChange={(e) => onUpdateTitle(e.target.value)}
                    placeholder="Introduceți explicatii suplimentare, daca sunt necesare ..."
                    className="w-full p-2 border rounded"
                />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-3">
                {/* Prima coloană - răspunsurile 1-3 */}
                <div className="flex flex-col gap-4">
                    {firstThreeAnswers.map((answer, index) => (
                        <div key={`answer-a-${answer.id}`} className="mb-2">
                            <div className="flex flex-col">
                                <label htmlFor={`multi-answer-a-${answer.id}`}
                                       className="font-medium text-gray-700 mb-1">
                                    {`${index + 1}(a)`}
                                </label>
                                <input
                                    id={`multi-answer-a-${answer.id}`}
                                    type="text"
                                    className="w-full p-2 border rounded"
                                    value={answer.text}
                                    onChange={(e) => onUpdateAnswer(answer.id, e.target.value)}
                                    placeholder={`Răspunsul ${index + 1}(a)...`}
                                />
                            </div>
                        </div>
                    ))}
                </div>

                {/* A doua coloană - răspunsurile 4-6 */}
                <div className="flex flex-col gap-4">
                    {lastThreeAnswers.map((answer, index) => (
                        <div key={`answer-b-${answer.id}`} className="mb-2">
                            <div className="flex flex-col">
                                <label htmlFor={`multi-answer-b-${answer.id}`}
                                       className="font-medium text-gray-700 mb-1">
                                    {`${index + 1}(b)`}
                                </label>
                                <input
                                    id={`multi-answer-b-${answer.id}`}
                                    type="text"
                                    className="w-full p-2 border rounded"
                                    value={answer.text}
                                    onChange={(e) => onUpdateAnswer(answer.id, e.target.value)}
                                    placeholder={`Răspunsul ${index + 1}(b)...`}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
