import React from 'react';
import {StatusIcon} from '../../../../../general';

export const TrueOrFalseTestView = ({data}) => {
    return (
        <div className="true-or-false-container bg-card-bg xl:w-[1000px] w-full flex flex-col gap-3 mt-5 mb-10 md:mb-15 p-3 md:p-6 border border-double border-medium-blue shadow-round-shadow border-x border-y">
            <h2 className="uppercase font-font-p tracking-wide font-semibold text-text-color text-2xl">
                Adevărat sau Fals - Rezultate
            </h2>
            {data.trueOrFalse.map((question, index) => (
                <div key={question.question_id}
                     className="true-or-false-item w-full mb-6 flex flex-col items-start justify-start gap-5 p-4 bg-card-bg border border-medium-blue">
                    <p className="font-font-s text-2xl font-bold">Întrebarea {index + 1}</p>
                    <p dangerouslySetInnerHTML={{__html: question.question_text}}
                       className="font-font-s font-normal text-text-color text-2xl bg-white p-3 rounded"/>
                    <div className="flex flex-col md:flex-row gap-3 justify-between items-center">
                        <div className="flex items-center mt-2">
                            <p className="font-font-s text-xl font-bold mr-2">Răspunsul tău:</p>
                            <p className={`font-font-s font-normal text-text-color text-xl p-2 rounded ${
                                question.user_answer === question.correct_answer
                                    ? 'border-olive-color bg-olive-color/50'
                                    : question.user_answer !== undefined
                                        ? 'border-peru-color bg-peru-color/50'
                                        : ''
                            }`}>
                                {question.user_answer === true ? 'Adevărat' : question.user_answer === false ? 'Fals' : 'Nu ai răspuns'}
                            </p>
                        </div>
                        <div className="flex items-center mt-2">
                            <p className="font-font-s text-xl font-bold mr-2">Răspunsul corect:</p>
                            <p className="font-font-s font-normal text-text-color text-2xl">
                                {question.correct_answer === true ? 'Adevărat' : 'Fals'}
                            </p>
                        </div>
                        <StatusIcon isCorrect={question.user_answer === question.correct_answer} size="lg"/>
                    </div>
                </div>
            ))}
        </div>
    );
};
