import React from 'react';
import {TestFeedback} from './TestFeedback';

export const EvNatTestResult = ({score, saveMessage}) => {
    const testHistory = `${window.BASE_URL}customer_account/tests/history/`;

    // Parsăm JSON-ul cu scorul dacă este în format string
    const scoreData = typeof score.test_score === 'string'
        ? JSON.parse(score.test_score)
        : score.test_score;

    // Extragem datele pentru afișare
    const totalScore = scoreData.total.score;
    const maxPossibleScore = scoreData.total.max_score;
    const scorePercentage = totalScore; // Scorul este deja procentual (din 100)

    // Calculăm numărul de răspunsuri corecte și totale dacă există
    const correctAnswers = scoreData.correct_answers || '?';
    const totalQuestions = scoreData.total_questions || '?';

    // Afișăm scorurile pentru fiecare secțiune
    const subiect1AScore = scoreData.subiect1A?.score || 0;
    const subiect1AMax = scoreData.subiect1A?.max_score || 0;

    const subiect1BScore = scoreData.subiect1B?.score || 0;
    const subiect1BMax = scoreData.subiect1B?.max_score || 0;

    const subiect2Score = scoreData.subiect2?.score || 0;
    const subiect2Max = scoreData.subiect2?.max_score || 0;

    const puncteOficiu = scoreData.puncte_oficiu || 0;

    return (
        <div className="bg-card-bg test-result flex flex-col items-center gap-6 p-6 max-w-2xl mx-auto mt-8 border-2 border-double border-medium-blue rounded-xl">
            <h2 className="text-4xl font-semibold text-text-color">
                Test Completat
            </h2>
            <div className="text-center">
                <p className="text-2xl mb-4">
                    Testul tău a fost înregistrat cu succes!
                </p>
                <p className="text-2xl font-font-s text-center text-text-color mb-3">
                    {'Vezi răspunsurile și rezultatul detaliat în'} {' '}
                    <a href={testHistory}
                       className="text-2xl font-semibold font-font-s text-center text-peru-color mb-3 no-underline hover:underline hover:text-text-color">
                        {'istoricul testelor'} {' '}
                    </a>
                    {'din contul tău de client.'}
                </p>
            </div>

            {/* Afișăm scorul total */}
            <div className="score-summary bg-white p-4 rounded-lg shadow-md w-full max-w-md">
                <h3 className="text-2xl font-semibold text-center mb-2">Rezultat Final</h3>
                <p className="text-6xl font-bold text-center text-olive-color mb-4">
                    {totalScore} / {maxPossibleScore} puncte
                </p>

                {/* Detalii pentru fiecare secțiune */}
                <div className="grid grid-cols-1 gap-2 mb-4">
                    <div className="text-2xl font-font-s flex justify-between">
                        <span>Subiectul I - Partea A:</span>
                        <span className="font-semibold">{subiect1AScore} / {subiect1AMax} puncte</span>
                    </div>
                    <div className="text-2xl font-font-s flex justify-between">
                        <span>Subiectul I - Partea B:</span>
                        <span className="font-semibold">{subiect1BScore} / {subiect1BMax} puncte</span>
                    </div>
                    <div className="text-2xl font-font-s flex justify-between">
                        <span>Subiectul II:</span>
                        <span className="font-semibold">{subiect2Score} / {subiect2Max} puncte</span>
                    </div>
                    <div className="text-2xl font-font-s flex justify-between">
                        <span>Puncte din oficiu:</span>
                        <span className="font-semibold">{puncteOficiu} puncte</span>
                    </div>
                </div>

            </div>

            {/* Folosim componenta de feedback existentă */}
            <TestFeedback score={scorePercentage} />

            {saveMessage && (
                <p className="text-olive-color font-font-s text-xl mt-4">
                    {saveMessage}
                </p>
            )}

            {/* Buton pentru a merge la istoricul testelor */}
            <a href={testHistory}
               className="text-2xl font-font-s mt-4 px-6 py-2 bg-peru-color text-white rounded-lg hover:bg-peru-color-dark hover:text-white transition-colors">
                Vezi istoricul testelor
            </a>
        </div>
    );
};
