import React from 'react';
import {Description} from "./ProductDetails/Description";
import {Review} from './ProductDetails/Review';
import {ReviewForm} from './ProductDetails/ReviewForm';
import {TestComponents} from "./ProductDetails/TestComponents";
import {AutoRelated} from "./ProductDetails/AutoRelated";

export const ProductDetails = ({productSku}) => {
    return (
        <div
            className={'w-full md:max-w-[1440px] flex flex-col justify-center items-center relative'}>
            <Description/>
            <TestComponents/>
            <AutoRelated/>
            <Review/>
            <ReviewForm productSku={productSku}/>
        </div>
    );
};
