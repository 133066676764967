import React, {useMemo} from 'react';
import {useCategory} from '../../../state';
import {BlockSk} from "../assets/BlockSk";

function decodeHtml(html: string | null) {
    if (!html) return null;
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

export const SubcategoryData: React.FC = () => {
    const {categoryData, loading, error} = useCategory();

    const decodedSubcategories = useMemo(() => {
        return categoryData?.children.map(subcat => ({
            ...subcat,
            description: decodeHtml(subcat.description)
        }));
    }, [categoryData?.children]);

    if (loading) return <BlockSk/>;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <div className={'flex flex-wrap flex-row justify-center md:justify-start items-center md:items-start gap-5 mb-3 md:mb-5'}>
            {decodedSubcategories?.map(subcat => (
                <div
                    key={subcat.id}
                    className={'bg-card-bg flex flex-wrap flex-row md:flex-col items-center md:justify-start justify-center ' +
                        'gap-3 p-4 w-auto md:min-w-[250px] w-[45%] min-w-[45%] ' +
                        'hover:shadow-round-shadow border-2 border-peru-color hover:border-olive-color rounded-xl'}
                >
                    <a href={`/${subcat.url_path}.html`}
                       className={'w-full hover:cursor:pointer hover:no-underline flex flex-row ' +
                           'md:flex-col items-center justify-center'}
                    >
                        <div className={'text-center flex flex-col md:flex-row items-center justify-center w-auto gap-3 pr-2'}>
                            <h3 className="font-font-p  tracking-wide text-text-color text-xl font-bold ">{subcat.name}</h3>
                            {subcat.image && (
                                <img src={subcat.image} alt={subcat.name}
                                     className="w-[100px] md:w-[70px] h-auto object-contain rounded-xl"/>
                            )}
                        </div>
                        {/*{subcat.description && (*/}
                        {/*    <div*/}
                        {/*        className={'font-font-s text-text-color text-xl h-56 md:h-72 overflow-hidden w-3/5 md:w-auto'}*/}
                        {/*        dangerouslySetInnerHTML={{__html: subcat.description}}*/}
                        {/*    />*/}
                        {/*)}*/}
                    </a>
                </div>
            ))}
        </div>
    );
};
